import CryptoIconManifest from "cryptocurrency-icons/manifest.json";

export type OptionShape = {
  symbol: string;
  name: string;
  color: string;
  label: string;
  value: string;
  uri: string;
};

export const CryptoOptions: OptionShape[] = CryptoIconManifest.map((i) => {
  return {
    ...i,
    label: `${i.name} ${i.symbol}`,
    value: i.symbol,
    uri: `https://storage.googleapis.com/opencraft-355017.appspot.com/crypto-png/color/${i.symbol.toLowerCase()}.png`,
  };
});

export const CryptoOptionsMap: Record<string, OptionShape> =
  CryptoOptions.reduce((prev, item) => {
    prev[item.symbol] = item;
    return prev;
  }, {});
