import { Company } from "@opencraft/common";
import React from "react";
import { Linking, View } from "react-native";
import { useNav } from "../app/navigation";
import { useBreakpoints } from "../util/hooks";
import { useTheme } from "../util/theme";
import { Avatar } from "./Avatar";
import { SocialIcons } from "./SocialIcons";
import { AppText as Text } from "./StyledText";
import { TokenMash } from "./TokenMash";

type Props = {
  company: Company;
  fullDescription?: boolean;
  /**
   * applies to deskstop only
   */
  width?: number;
};

export const CompanyPreview = ({
  company,
  fullDescription,
  width = 250,
}: Props) => {
  const nav = useNav("Home");
  const theme = useTheme();

  const [mobile, tablet] = useBreakpoints();

  return (
    <View
      style={
        tablet
          ? { alignItems: "center", width }
          : {
              flexDirection: "row",
              alignItems: "center",
            }
      }
    >
      <Avatar
        profile={company}
        onPress={() => {
          nav.navigate("App", {
            screen: "CompanyDetail",
            params: {
              id: company.id,
            },
          });
        }}
        size={tablet ? undefined : 50}
      />
      <View
        style={{
          marginLeft: tablet ? 0 : 20,
        }}
      >
        <Text
          style={{
            textAlign: tablet ? "center" : "left",
            fontWeight: "bold",
            textTransform: "uppercase",
            marginTop: tablet ? 10 : 0,
          }}
        >
          {company.name}
        </Text>

        <Text
          style={{
            textAlign: "center",

            marginTop: 5,
            color: theme.colors.primary,
            textDecorationLine: "underline",
          }}
          onPress={() => {
            Linking.openURL(company.website);
          }}
        >
          {company.website}
        </Text>
      </View>
      {tablet && (
        <Text
          numberOfLines={fullDescription ? undefined : 3}
          style={{
            textAlign: "left",

            marginTop: 10,
          }}
        >
          {company.description}
        </Text>
      )}
      {tablet && (
        <View>
          <SocialIcons links={["discord", "twitter"]} profile={company} />
        </View>
      )}
      {tablet && (
        <View
          style={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text style={{ marginBottom: 10, fontWeight: "bold" }}>
            Makes payments in:
          </Text>
          <TokenMash tokens={company.currencies} centered />
        </View>
      )}
    </View>
  );
};
