import React, { useState } from "react";
import { Image, Pressable, StyleSheet } from "react-native";
import { RootNavParams, useNav } from "../app/navigation";

import { useTheme } from "../util/theme";

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { OnboardingView } from "../components/OnboardingView";

import * as ImagePicker from "expo-image-picker";
import { useEffect } from "react";
import { AppText as Text } from "../components/StyledText";
import { useOrEstablishProfile } from "../util/auth";

type Props = NativeStackScreenProps<RootNavParams, "OnboardingFreelancerPhoto">;

export const OnboardingFreelancerPhoto = (props: Props) => {
  const { step } = props.route.params;
  const nav = useNav("OnboardingFreelancerPhoto");
  const theme = useTheme();

  const [_, profile] = useOrEstablishProfile();
  const [image, setImage] = useState("");

  useEffect(() => {
    if (profile && profile.pic) {
      setImage(profile.pic);
    } else if (profile) {
      setImage(`https://avatars.dicebear.com/api/identicon/${profile.id}.svg`);
    }
  }, [profile]);

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      quality: 1,
    });

    if (result.cancelled === false) {
      setImage(result.uri);
    }
  };

  return (
    <OnboardingView
      step={parseInt(step)}
      title="Choose an Avatar"
      description="Like your handle, you can choose a real photo, upload an avatar (NFT support coming) or stick with your identicon. Some projects may prefer doxxed accounts, while some may be open to anon."
      onNext={() => {
        nav.navigate("App", {
          screen: "JobSearch",
        });
      }}
    >
      <Pressable
        onPress={pickImage}
        style={{
          marginVertical: 40,
          justifyContent: "center",
          width: 250,
          alignItems: "center",
          shadowColor: "rgba(0, 0, 0, .5)",
          shadowRadius: 2,
          borderRadius: theme.roundness,
          padding: 20,
        }}
      >
        {image && (
          <Image
            style={{
              height: 150,
              width: 150,
              borderRadius: 75,
              borderWidth: 3,
              borderColor: theme.colors.primary,
            }}
            source={{
              uri: image,
            }}
          />
        )}
        {profile && (
          <Text
            style={{
              textAlign: "center",
              fontSize: 25,
              marginVertical: 20,
            }}
          >
            {profile.handle}
          </Text>
        )}
      </Pressable>
    </OnboardingView>
  );
};

const S = StyleSheet.create({});
