import React, { useEffect, useState } from "react";

import { useCurrentUser, useRequiredCurrentUser } from "../util/auth";
import { Spacing, useTheme } from "../util/theme";

import {
  Experience,
  Profile,
  ProfileRepo,
  RemotePreference,
  Web3Experience,
  WorkStyle,
} from "@opencraft/common";
import { RouteProp, useRoute } from "@react-navigation/native";
import { Dimensions, ScrollView, StyleSheet, Switch, View } from "react-native";
import { AppNavParams } from "../app/navigation";
import { Avatar } from "../components/Avatar";
import { Button } from "../components/Button";
import { ButtonSelect } from "../components/ButtonSelect";
import { Card } from "../components/Card";
import { ContactButton } from "../components/ContactButton";
import { DetailRow } from "../components/DetailRow";
import { Dialog } from "../components/Dialog";
import { IconButton } from "../components/IconButton";
import { Loader } from "../components/Loader";
import { Pill } from "../components/Pill";
import { ProfileCompletionCard } from "../components/ProfileCompletionCard";
import { CurrencySelect, TimezoneSelect } from "../components/Selects";
import { SocialIcons } from "../components/SocialIcons";
import { AppText as Text, Label, Title } from "../components/StyledText";
import { TextInput } from "../components/TextInput";
import { TokenMash } from "../components/TokenMash";
import { useCompany, useImagePicker } from "../util/hooks";
import { useRepo } from "../util/store";
import { useNotify } from "../util/toast";

export const ExperienceOptions: {
  value: Experience;
  label: Experience;
}[] = Object.values(Experience).map((k) => {
  return {
    value: k,
    label: k,
  };
});

export const WorkStyleOptions: {
  value: WorkStyle;
  label: WorkStyle;
}[] = Object.values(WorkStyle).map((k) => {
  return {
    value: k,
    label: k,
  };
});

export const Web3ExperienceOptions: {
  value: Web3Experience;
  label: Web3Experience;
}[] = Object.values(Web3Experience).map((k) => {
  return {
    value: k,
    label: k,
  };
});

export const RemotePreferenceOptions: {
  value: RemotePreference;
  label: RemotePreference;
}[] = Object.values(RemotePreference).map((k) => {
  return {
    value: k,
    label: k,
  };
});

export const ProfileDetail = () => {
  const user = useCurrentUser();
  const theme = useTheme();

  const route = useRoute<RouteProp<AppNavParams, "ProfileDetail">>();

  const id = route.params.id;

  if (!id) {
    throw new Error("this route requires an ID");
  }

  const [loadingCompany, company] = useCompany();

  const [modalVisible, setModalVisible] = useState(false);
  const [saving, setSaving] = useState(false);
  const [changes, setChanges] = useState<Partial<Profile>>({});
  const [infoModalVisible, setInfoModalVisible] = useState(false);

  const { image, pickImage, saveImage } = useImagePicker();
  const { useGet, update } = useRepo(ProfileRepo);

  const [loading, profile] = useGet(id);

  const toast = useNotify();

  const { height } = Dimensions.get("window");

  useEffect(() => {
    if (profile) {
      setChanges(profile);
    }
  }, [profile]);

  if (!profile || loading) {
    return <Loader visible />;
  }

  const isOwner = user && id === user.id;

  return (
    <ScrollView style={S.page}>
      {isOwner && <ProfileCompletionCard hideButton />}
      <Card
        Header={() => {
          return isOwner ? (
            <View
              style={{
                backgroundColor: profile.published
                  ? theme.colors.primary
                  : theme.colors.disabled,
                justifyContent: "flex-end",
                flexDirection: "row",
                alignItems: "center",
                padding: theme.spacing.gutter,
              }}
            >
              <Text
                style={{
                  marginRight: 10,
                  color: profile.published ? "white" : "black",
                }}
              >
                {profile.published ? "Deactivate" : "Activate"}
              </Text>
              <Switch
                trackColor={{
                  true: "white",
                  false: "#e5e5e5",
                }}
                value={profile.published}
                onValueChange={async (val) => {
                  await update(profile.id, {
                    published: val,
                  });
                  if (val) {
                    toast("Congrats! Your profile is now visible.");
                  }
                }}
              />
            </View>
          ) : null;
        }}
      >
        {isOwner && (
          <View
            style={{
              position: "absolute",
              top: theme.spacing.gutter,
              right: theme.spacing.gutter,
              zIndex: 5,
            }}
          >
            <IconButton
              color={theme.colors.disabled}
              hoverColor={theme.colors.primary}
              size={25}
              onPress={() => setInfoModalVisible(true)}
              content="Edit info"
              name="edit"
            />
          </View>
        )}
        <View style={S.mainInfo}>
          <Avatar
            size={150}
            profile={profile}
            onPress={() => {
              console.log("pressed");
              setModalVisible(true);
            }}
          />
          <View style={S.bioInfo}>
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              {profile.handle ? (
                <Title>{profile.handle}</Title>
              ) : (
                <Title style={S.disabledText}>Set a handle</Title>
              )}
              {profile.role && (
                <Pill
                  style={{ marginLeft: 10, alignSelf: "center" }}
                  color={theme.colors.accent}
                >
                  {profile.role}
                </Pill>
              )}
            </View>
            {profile.bio ? (
              <Text style={{ marginTop: 10 }}>{profile.bio}</Text>
            ) : (
              <Text style={[S.disabledText, { marginTop: 10 }]}>Add a bio</Text>
            )}
            <SocialIcons
              profile={profile}
              editable
              onEdit={async (result) => {
                await update(profile.id, result);
              }}
            />
          </View>
        </View>
        <View style={S.basicDetails}>
          <Text style={[S.basicDetailsTitle]}>Basic Details</Text>
          <View style={S.tokenPreferrences}></View>

          <DetailRow
            icon={{
              name: "build-outline",
              label: "Crypto Experience",
            }}
            Value={profile.web3Exp}
          />
          <DetailRow
            icon={{
              name: "globe-outline",
              label: "Timezone",
            }}
            Value={profile.timezone}
          />
          <DetailRow
            icon={{
              name: "logo-bitcoin",
              label: "Accepted Currencies",
            }}
            Value={() => {
              return (
                <View style={{ alignItems: "center", flexDirection: "row" }}>
                  <Text style={{ marginRight: 2 }}>Accepts payments in</Text>
                  {profile.preferredCurrencies &&
                  profile.preferredCurrencies.length > 0 ? (
                    <TokenMash tokens={profile.preferredCurrencies} />
                  ) : (
                    <Text style={[S.disabledText, { marginLeft: 10 }]}>
                      No currencies selected
                    </Text>
                  )}
                </View>
              );
            }}
          />
          <DetailRow
            icon={{
              name: "bulb-outline",
              label: "Experience",
            }}
            Value={profile.experience}
          />
          <DetailRow
            icon={{
              name: "briefcase-outline",
              label: "Remote Preference",
            }}
            Value={profile.remote}
          />
          <DetailRow
            icon={{
              name: "game-controller-outline",
              label: "Work Style",
            }}
            Value={profile.style}
          />

          <Dialog
            visible={modalVisible}
            title="Update Profile Photo"
            onDismiss={() => setModalVisible(false)}
          >
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                width: "100%",
                minWidth: 600,
              }}
            >
              <View style={{ marginVertical: 10 }}>
                <Avatar
                  size={200}
                  profile={profile}
                  override={image && image.uri}
                />
              </View>
              {!profile.pic && !image ? (
                <Text style={{ marginBottom: 20, marginTop: 10 }}>
                  You're currently using a randomly generated identicon. Upload
                  your own pic to your profile.
                </Text>
              ) : null}

              <Button
                onPress={async () => {
                  if (!image) {
                    await pickImage();
                  } else {
                    const uri = await saveImage();

                    update(profile.id, {
                      pic: uri,
                    });
                  }
                }}
                icon={image ? `checkmark-circle-outline` : `camera-outline`}
                label={image ? `Save` : `Choose profile photo`}
              />
            </View>
          </Dialog>
          <Dialog
            visible={infoModalVisible}
            title="Update Your Profile"
            scrollable
            onDismiss={() => setInfoModalVisible(false)}
          >
            <View
              // contentContainerStyle={{
              //   justifyContent: "flex-start",
              // }}
              style={{
                width: 450,

                paddingTop: theme.spacing.padding,
              }}
            >
              <Label>Handle</Label>
              <TextInput
                focusable
                placeholder="e.g., Codeslinger or George or something cool"
                onChangeText={(val) => {
                  setChanges({ ...changes, handle: val });
                }}
                value={changes.handle}
              />
              <Label>Bio</Label>
              <TextInput
                focusable
                placeholder="A bit about you..."
                multiline
                numberOfLines={4}
                value={changes.bio}
                onChangeText={(val) => {
                  setChanges({ ...changes, bio: val });
                }}
              />
              <Label>Role</Label>
              <TextInput
                focusable
                value={changes.role}
                placeholder="e.g., Engineer or Photographer"
                onChangeText={(val) => {
                  setChanges({ ...changes, role: val });
                }}
              />
              <Label>Timezone</Label>
              <TimezoneSelect
                value={changes.timezone}
                onChange={(val) => {
                  setChanges({ ...changes, timezone: val });
                }}
              />
              <View style={{ height: 20 }}></View>
              <Label>Preferred Currencies</Label>
              <CurrencySelect
                value={changes.preferredCurrencies || []}
                onChange={(val) => {
                  setChanges({ ...changes, preferredCurrencies: val });
                }}
              />
              <View style={{ height: 20 }}></View>
              <ButtonSelect
                options={ExperienceOptions}
                value={changes.experience}
                label="General Experience"
                onChange={(val) => {
                  setChanges({ ...changes, experience: val as Experience });
                }}
              />
              <ButtonSelect
                options={WorkStyleOptions}
                value={changes.style}
                label="Work style"
                onChange={(val) => {
                  setChanges({ ...changes, style: val as WorkStyle });
                }}
              />
              <ButtonSelect
                options={Web3ExperienceOptions}
                value={changes.web3Exp}
                label="Web3 Experience"
                onChange={(val) => {
                  setChanges({ ...changes, web3Exp: val as Web3Experience });
                }}
              />
              <ButtonSelect
                options={RemotePreferenceOptions}
                value={changes.remote}
                label="Remote Preference"
                onChange={(val) => {
                  setChanges({ ...changes, remote: val as RemotePreference });
                }}
              />
              <Button
                label="save"
                size="small"
                loading={saving}
                disabled={saving}
                onPress={async () => {
                  setSaving(true);
                  await update(user.id, {
                    ...changes,
                  });
                  setSaving(false);
                  setInfoModalVisible(false);
                }}
              />
            </View>
          </Dialog>
        </View>
        <View style={{ alignSelf: "center" }}>
          {!isOwner && <ContactButton profile={profile} company={company} />}
        </View>
      </Card>
    </ScrollView>
  );
};

const S = StyleSheet.create({
  page: {
    padding: Spacing.spacing.margin,
    flex: 1,
  },

  section: {
    marginBottom: Spacing.spacing.margin,
    marginHorizontal: Spacing.spacing.margin,
    maxWidth: 1100,
  },

  mainInfo: {
    flexDirection: "row",
  },

  tokenPreferrences: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },

  bioInfo: {
    flex: 1,
    paddingHorizontal: Spacing.spacing.gutter,
  },

  disabledText: {
    color: "#ccc",
    fontStyle: "italic",
  },

  basicDetails: {
    paddingVertical: Spacing.spacing.padding,
  },

  basicDetailsTitle: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
});
