import React from "react";
import { StyleSheet, View } from "react-native";
import { Button } from "../components/Button";
import { AppText as Text } from "./StyledText";
import { Fonts, useTheme } from "../util/theme";
import { Loader } from "./Loader";

type BaseProps = {
  step: number;
  title: string;
  loading?: boolean;
  description: string;
  children: React.ReactNode;
};

type NoActionProps = BaseProps & {
  noAction: true;
};

type NextProps = BaseProps & {
  onNext: () => void;
};

type Props = NoActionProps | NextProps;

export const OnboardingView = (props: Props) => {
  const theme = useTheme();

  const { step, title, description, loading, children } = props;

  let onNext = undefined;
  let noAction = false;

  if (!("noAction" in props)) {
    onNext = props.onNext;
  } else {
    noAction = props.noAction;
  }

  return (
    <View
      style={[
        S.container,
        {
          backgroundColor: theme.colors.background,
        },
      ]}
    >
      <Loader visible={loading} />
      <View style={S.content}>
        <View>
          <Text style={[S.pill, { backgroundColor: theme.colors.primary }]}>
            Step {step}
          </Text>
          <Text style={S.title}>{title}</Text>
          <Text style={S.subtitle}>{description}</Text>

          <View>{children}</View>
        </View>
        {!noAction ? (
          <View style={{ alignSelf: "flex-start" }}>
            <Button
              format="filled"
              label="Next"
              onPress={() => {
                onNext();
              }}
            ></Button>
          </View>
        ) : null}
      </View>
      <View style={S.decoration}></View>
    </View>
  );
};

const S = StyleSheet.create({
  container: {
    flex: 1,

    flexDirection: "row",
  },

  content: {
    flex: 2,
    padding: 100,
    justifyContent: "space-between",
  },

  pill: {
    padding: 5,
    borderRadius: 4,
    color: "white",
    alignSelf: "flex-start",
    fontWeight: "bold",
  },

  decoration: {
    flex: 1,
  },

  nav: {
    backgroundColor: "white",
    height: 100,
    flexDirection: "row-reverse",
    alignItems: "center",
    paddingHorizontal: 20,
  },

  title: {
    fontSize: 45,
    marginBottom: 20,

    fontFamily: Fonts.fonts.title,
  },

  subtitle: {
    fontSize: 18,
  },

  page: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
});
