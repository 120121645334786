import React from "react";
import { TextProps, Text, StyleSheet } from "react-native";
import { useTheme } from "../util/theme";

type Props = TextProps & {
  color: string;
};

export const Pill = (props: Props) => {
  const theme = useTheme();

  return (
    <Text
      {...props}
      style={[
        S.pill,
        props.style,
        {
          backgroundColor: props.color,
          borderRadius: theme.roundness,
        },
      ]}
    />
  );
};

const S = StyleSheet.create({
  pill: {
    color: "white",
    paddingHorizontal: 5,
    paddingVertical: 2,
    fontWeight: "bold",

    alignSelf: "flex-start",
  },
});
