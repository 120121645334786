import { Ionicons } from "@expo/vector-icons";
import {
  CompanyRepo,
  GetIDPartsFromConnectionID,
  GetMessagesRepo,
  MessageConnectionRepo,
  ProfileRepo,
} from "@opencraft/common";
import { RouteProp, useRoute } from "@react-navigation/native";
import React, { useEffect, useMemo, useState } from "react";
import { FlatList, StyleSheet, View } from "react-native";
import { MessageNavParams, useNav } from "../app/navigation";
import { Avatar } from "../components/Avatar";
import { Button } from "../components/Button";
import { Loader, loading } from "../components/Loader";
import { AppText as Text } from "../components/StyledText";
import { TextInput } from "../components/TextInput";
import { useCurrentUser } from "../util/auth";
import { isUnread, markAsRead } from "../util/messageConnection";
import { useRepo } from "../util/store";
import { Spacing, useTheme } from "../util/theme";

export const MessageDetail = () => {
  const theme = useTheme();

  const user = useCurrentUser();

  const route = useRoute<RouteProp<MessageNavParams, "MessageDetail">>();

  const { id } = route.params;

  const [height, setHeight] = useState(80);
  const [content, setContent] = useState("");
  const [sending, setSending] = useState(false);

  const { useGet: useGetConnection } = useRepo(MessageConnectionRepo);
  const { useAll, create } = useRepo(GetMessagesRepo(id));
  const { useGet } = useRepo(CompanyRepo);
  const { useGet: useGetProfile } = useRepo(ProfileRepo);

  const { userId, companyId } = GetIDPartsFromConnectionID(id);

  const [loadingCompany, company] = useGet(companyId);
  const [loadingProfile, profile] = useGetProfile(userId);

  const [loadingMessages, data] = useAll([id]);
  const [_, connection] = useGetConnection(id);

  const from = user.id === userId ? profile : company;

  useEffect(() => {
    if (connection && company && user && isUnread(connection, company, user)) {
      markAsRead(connection.id);
    }
  }, [connection, user, company]);

  useMemo(() => {
    data.sort((a, b) => {
      return b.createdAt.toMillis() - a.createdAt.toMillis();
    });
  }, [data]);

  return (
    <View
      style={[
        S.page,
        {
          backgroundColor: theme.colors.background,
          padding: theme.spacing.gutter,
        },
      ]}
    >
      <Loader
        visible={loading(loadingCompany, loadingMessages, loadingProfile)}
      />
      <View>
        <TextInput
          value={content}
          onChangeText={setContent}
          style={{
            height: height,
            maxHeight: 150,
            backgroundColor: theme.colors.ambientBG,
          }}
          onContentSizeChange={(e) => {
            console.log(e.nativeEvent);
            // setHeight(e.nativeEvent.contentSize.height);
          }}
          multiline
          placeholder="Type something..."
        />
        <Button
          loading={sending}
          onPress={async () => {
            setSending(true);
            create({
              content,
              connection: id,
              from: from.id,
            });
            setContent("");
            setSending(false);
          }}
          style={{ alignSelf: "flex-end" }}
          label="send"
        />
      </View>
      <FlatList
        style={{ marginBottom: 10 }}
        data={data}
        inverted
        renderItem={({ item, index }) => {
          const prev = index <= data.length ? data[index + 1] : null;
          return (
            <View style={{ flexDirection: "row", marginBottom: 10 }}>
              {!prev || prev.from != item.from ? (
                <View>
                  <Avatar
                    size={40}
                    profile={item.from === user.id ? profile : company}
                  />
                </View>
              ) : (
                <View style={{ marginLeft: 40 }} />
              )}

              <View style={{ marginLeft: 10 }}>
                {!prev || prev.from != item.from ? (
                  <Text style={{ fontWeight: "bold", marginBottom: 5 }}>
                    {item.from === user.id ? profile.handle : company.name}
                  </Text>
                ) : null}
                <Text>{item.content}</Text>
              </View>
            </View>
          );
        }}
        ListEmptyComponent={() => <Text>No messages yet</Text>}
      />
    </View>
  );
};

const S = StyleSheet.create({
  page: {
    flex: 1,
    flexDirection: "column-reverse",
  },
  messageEmpty: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: Spacing.spacing.margin,
  },

  messageEmptyText: {
    marginBottom: Spacing.spacing.gutter,
    paddingHorizontal: Spacing.spacing.gutter,
  },
});

export const MessageDetailEmpty = () => {
  const theme = useTheme();
  const nav = useNav("App");

  return (
    <View
      style={[S.messageEmpty, { backgroundColor: theme.colors.background }]}
    >
      <Ionicons
        name="chatbubble-outline"
        size={75}
        color={theme.colors.accent}
      />
      <Text style={S.messageEmptyText}>
        No messages yet. Browse open jobs to apply.
      </Text>
      <Button
        label="Browse Jobs"
        onPress={() => {
          nav.navigate("App", {
            screen: "JobSearch",
          });
        }}
      />
    </View>
  );
};
