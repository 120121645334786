import { Ionicons } from "@expo/vector-icons";
import { Company, MessageConnection } from "@opencraft/common";
import React, { useState } from "react";
import { FlatList, Pressable, StyleSheet, View } from "react-native";
import { useNav } from "../app/navigation";
import { ChatPreview } from "../components/ChatPreview";
import { EmailDialog } from "../components/EmailDialog";
import { AppText as Text } from "../components/StyledText";
import { useRequiredCurrentUser } from "../util/auth";
import { useBreakpoints } from "../util/hooks";
import { Spacing, useTheme } from "../util/theme";

type ProvidedProps = {};
type Props = ProvidedProps & {
  preloaded: {
    company?: Company;
    connections: MessageConnection[];
  };
  active?: string;
};

export const MessagesView = (props: Props) => {
  const user = useRequiredCurrentUser();
  const theme = useTheme();

  const nav = useNav("App");

  const [modalVisible, setModalVisible] = useState(false);

  const [mobile, tablet] = useBreakpoints();

  const { company, connections } = props.preloaded;

  return (
    <>
      <FlatList
        data={connections}
        contentContainerStyle={{
          position: "relative",
        }}
        ListHeaderComponent={() => {
          if (!user.email) {
            return (
              <Pressable
                onPress={() => {
                  setModalVisible(true);
                }}
                style={{
                  backgroundColor: theme.colors.error,
                  padding: theme.spacing.gutter,
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <Ionicons
                  name="warning"
                  size={20}
                  color="white"
                  style={{ marginRight: 10 }}
                />
                <Text
                  style={{
                    color: "white",
                  }}
                >
                  No email registered
                </Text>
              </Pressable>
            );
          }

          return null;
        }}
        ListEmptyComponent={() => {
          return (
            <View style={S.messageEmpty}>
              <Ionicons
                name="chatbubble"
                size={40}
                color={theme.colors.accent}
              />
              <Text style={S.messageEmptyText}>
                Your chats with different companies and projects will show up
                here.
              </Text>
            </View>
          );
        }}
        renderItem={({ item }) => (
          <ChatPreview
            onPress={() => {
              if (tablet) {
                nav.navigate("App", {
                  screen: "Messages",
                  params: {
                    screen: "MessageDetail",
                    params: {
                      id: item.id,
                    },
                  },
                });
              } else {
                nav.navigate("App", {
                  screen: "MessageDetail",
                  params: {
                    id: item.id,
                  },
                });
              }
            }}
            connection={item}
          />
        )}
        style={[
          S.leftNav,
          {
            borderRightColor: theme.colors.border,
            backgroundColor: theme.colors.background,
          },
          tablet && {
            width: 300,
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            borderRightWidth: 1,
            alignSelf: "flex-start",
            flex: 1,
            zIndex: 4,
          },
        ]}
      />
      <EmailDialog
        visible={modalVisible}
        onDismiss={() => setModalVisible(false)}
      />
    </>
  );
};

const S = StyleSheet.create({
  page: {
    flex: 1,
    flexDirection: "row",
    alignSelf: "flex-start",
  },
  leftNav: {
    flex: 1,
  },

  messageContent: {
    flex: 1,
  },

  messageEmpty: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: Spacing.spacing.margin,
  },

  messageEmptyText: {
    marginBottom: Spacing.spacing.gutter,
    paddingHorizontal: Spacing.spacing.gutter,
  },
});
