import React from "react";
import { Image, View } from "react-native";
import { Popable } from "react-native-popable";
import { CryptoOptionsMap } from "../util/crypto";

type Props = {
  tokens: string[];
  centered?: boolean;
};

export const TokenMash = ({ tokens, centered }: Props) => {
  console.log(tokens, "tokens");
  const items = tokens
    ? tokens.map((t) => CryptoOptionsMap[t.toUpperCase()]).filter((t) => t)
    : [];

  return (
    <View
      style={{
        flexDirection: "row",
        alignSelf: centered ? "center" : "flex-start",
      }}
    >
      {items.map((t) => {
        return (
          <View
            key={t.symbol}
            style={{
              borderWidth: 2,
              borderRadius: 20,
              borderColor: "white",
              marginHorizontal: 2,
            }}
          >
            <Popable action="hover" content={`${t.name} (${t.symbol})`}>
              <Image
                style={{ height: 20, width: 20 }}
                source={{ uri: t.uri }}
              />
            </Popable>
          </View>
        );
      })}
    </View>
  );
};
