import { User } from "@opencraft/common";
import React, { useEffect, useState } from "react";
import { Loader } from "../components/Loader";
import { useCurrentUser } from "./auth";

export type PreloadableProps<T> = {
  preloaded: T;
};

// M = provided props
// P = resulting props
// T = resulting data from provided props

export function Preload<M = {}, T = {}>(
  Component: (
    props: PreloadableProps<T> & M
  ) => React.ReactElement<PreloadableProps<T> & M>,
  provider: (
    providedProps: M,
    context: {
      currentUser?: User;
    }
  ) => Promise<T>
) {
  return (props: M) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<T | null>(null);

    const user = useCurrentUser();

    useEffect(() => {
      const load = async () => {
        const result = await provider(props, { currentUser: user });
        setData(result);
        setLoading(false);
      };

      load();
    }, []);

    if (loading) {
      return <Loader visible />;
    }

    return <Component preloaded={data} {...props} />;
  };
}
