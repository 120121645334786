import { UserRepo } from "@opencraft/common";
import React, { useState } from "react";
import { View } from "react-native";
import isEmail from "validator/lib/isEmail";
import { Button } from "../components/Button";
import { TextInput } from "../components/TextInput";
import { useCurrentUser } from "../util/auth";
import { useRepo } from "../util/store";
import { useTheme } from "../util/theme";
import { Dialog } from "./Dialog";

type Props = {
  visible: boolean;
  onDismiss: () => void;
};

export const EmailDialog = (props: Props) => {
  const theme = useTheme();
  const user = useCurrentUser();
  const repo = useRepo(UserRepo);
  const [email, setEmail] = useState(user.email);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");

  return (
    <Dialog
      {...props}
      title="Add your email"
      Footer={
        <Button
          label="Save"
          size="small"
          loading={saving}
          onPress={async () => {
            if (isEmail(email)) {
              setSaving(true);
              await repo.update(user.id, {
                email,
              });
              setSaving(false);
              props.onDismiss();
            } else {
              setError("Please enter a valid email");
            }
          }}
        />
      }
    >
      <View style={{ flex: 1 }}>
        <TextInput
          placeholder="add your email"
          value={email}
          style={{ width: "100%" }}
          onChangeText={setEmail}
        />
      </View>
    </Dialog>
  );
};
