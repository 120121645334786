import {
  Company,
  MessageConnection,
  MessageConnectionID,
  MessageConnectionRepo,
  User,
} from "@opencraft/common";

export const isUnread = (m: MessageConnection, c: Company, u?: User) => {
  return (
    m.lastMessage &&
    !m.lastMessage.read &&
    ((m.lastMessage.from == m.company && m.user == u?.id) ||
      (m.lastMessage.from == m.user && m.company == c.id))
  );
};

export const markAsRead = (id: MessageConnectionID) => {
  const repo = MessageConnectionRepo;

  repo.update({
    id,
    // @ts-ignore TODO: write updates to support dot field
    ["lastMessage.read"]: true,
  });
};
