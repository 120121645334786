import { Company, Profile, SendIntro } from "@opencraft/common";
import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { useNav } from "../app/navigation";
import { useTheme } from "../util/theme";
import { Button } from "./Button";
import { Dialog } from "./Dialog";
import { TextInput } from "./TextInput";
import { AppText as Text } from "./StyledText";
import { useCurrentUser, useTriggerLogin } from "../util/auth";

type Props = {
  profile: Profile;
  company?: Company;
};

export const ContactButton = ({ profile, company }: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [sent, setSent] = useState(false);
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);

  const nav = useNav("App");

  const theme = useTheme();

  const currentUser = useCurrentUser();
  const triggerLogin = useTriggerLogin();

  useEffect(() => {
    if (sent) {
      setTimeout(() => {
        setModalVisible(false);
      }, 1500);
    }
  }, [sent]);

  return (
    <View
      style={{ flex: 1, alignContent: "flex-end", justifyContent: "flex-end" }}
    >
      <Button
        style={{ marginTop: 20, alignSelf: "center" }}
        label="Contact"
        onPress={() => {
          if (currentUser) {
            setModalVisible(true);
          } else {
            triggerLogin();
          }
        }}
        format="outline"
        color={theme.colors.primary}
      />
      <Dialog
        title={`Reach out to ${profile.handle}`}
        visible={modalVisible}
        onDismiss={() => {
          setModalVisible(false);
        }}
        Footer={
          <>
            {company ? (
              <Button
                style={{ alignSelf: "flex-end", marginTop: 10 }}
                label={sent ? "sent" : sending ? "sending" : "send"}
                color={sent ? theme.colors.success : undefined}
                loading={sending}
                disabled={sending}
                onPress={async () => {
                  if (company) {
                    setSending(true);
                    await SendIntro({
                      to: profile,
                      from: company,
                      content: message,
                    });
                    setSending(false);
                    setSent(true);
                  }
                }}
              />
            ) : (
              <Button
                style={{ alignSelf: "center", marginTop: 20 }}
                label="Register"
                color={theme.colors.accent}
                onPress={() => {
                  setModalVisible(false);
                  nav.navigate("OnboardingProjectStart", {
                    step: "1",
                  });
                }}
              ></Button>
            )}
          </>
        }
      >
        <View style={{ minWidth: 350, flex: 1 }}>
          {company ? (
            <View style={{ flex: 1, justifyContent: "space-between" }}>
              <TextInput
                placeholder={`What do you want to say?`}
                numberOfLines={5}
                multiline
                value={message}
                onChangeText={setMessage}
                style={{
                  borderWidth: 1,
                  marginTop: 10,
                  flex: 1,

                  borderColor: theme.colors.disabled,
                  padding: 10,
                  width: "100%",
                }}
              />
            </View>
          ) : (
            <View>
              <Text style={{ marginVertical: 20 }}>
                Please first register as an organization.
              </Text>
            </View>
          )}
        </View>
      </Dialog>
    </View>
  );
};
