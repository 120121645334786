import {
  OpenSans_800ExtraBold,
  useFonts,
  OpenSans_400Regular,
} from "@expo-google-fonts/open-sans";
import React, { useState } from "react";
import { ActivityIndicator, View, useColorScheme } from "react-native";
import "react-native-gesture-handler";
import { RootSiblingParent } from "react-native-root-siblings";
import { signout } from "./src/app/auth";
import { RootNavigator, RootNavWrapper } from "./src/app/navigation";
import { useLogin, UserContext } from "./src/util/auth";
import { getTheme, ThemeContext } from "./src/util/theme";

export default function App() {
  let [fontsLoaded] = useFonts({
    OpenSans_800ExtraBold,
    OpenSans_400Regular,
  });

  // const scheme = useColorScheme();
  const [theme, setTheme] = useState<"light" | "dark">("dark");

  const [loading, currentUser] = useLogin();
  const [loginModalVisible, setLoginModalVisible] = useState(false);

  if (loading || !fontsLoaded) {
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <ActivityIndicator />
      </View>
    );
  }

  return (
    <RootSiblingParent>
      <UserContext.Provider
        value={{
          loginModalShouldShow: loginModalVisible,
          currentUser,
          logout: () => {
            signout();
            window.location.href = "/signin";
          },
          login: () => {
            setLoginModalVisible(true);
          },
          cancelLogin: () => {
            setLoginModalVisible(false);
          },
        }}
      >
        <ThemeContext.Provider value={getTheme(theme)}>
          <RootNavWrapper>
            <RootNavigator />
          </RootNavWrapper>
        </ThemeContext.Provider>
      </UserContext.Provider>
    </RootSiblingParent>
  );
}
