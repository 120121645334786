import React, { useState } from "react";
import { Pressable } from "react-native";
import { useTheme } from "../util/theme";
import { AppText as Text } from "./StyledText";

type Props = {
  onPress: () => void;
  active: boolean;
  label: string;
};

export const NavLink = (props: Props) => {
  const theme = useTheme();

  const [hovering, setHovering] = useState(false);

  return (
    <Pressable
      // @ts-ignore
      onHoverIn={() => {
        setHovering(true);
      }}
      onHoverOut={() => {
        setHovering(false);
      }}
      style={{
        marginRight: 20,
        padding: 10,
        borderRadius: theme.roundness,
        // @ts-ignore
        // transition: "background 200ms",
        backgroundColor:
          hovering || props.active ? theme.colors.primary : "transparent",
      }}
      onPress={() => {
        props.onPress();
      }}
    >
      <Text
        style={{
          fontSize: 16,
          fontWeight: "bold",
        }}
      >
        {props.label}
      </Text>
    </Pressable>
  );
};
