import React, { useState } from "react";
import { Linking, View } from "react-native";
import { useTheme } from "../util/theme";
import { Button } from "./Button";
import { Dialog } from "./Dialog";
import { IconButton } from "./IconButton";
import { TextInput } from "./TextInput";

interface HasSocialLinks {
  github?: string;
  linkedIn?: string;
  discord?: string;
  twitter?: string;
}

type EditableProfile = {
  profile: HasSocialLinks;
  links?: (keyof HasSocialLinks)[];
  editable: true;
  onEdit: (result: HasSocialLinks) => Promise<void>;
};

type NonEditableProfile = {
  profile: HasSocialLinks;
  links?: (keyof HasSocialLinks)[];
  editable?: false;
};

type Props = NonEditableProfile | EditableProfile;

export const SocialIcons = (props: Props) => {
  const { links, profile, editable } = props;
  const theme = useTheme();
  const [editing, setEditing] = useState<keyof HasSocialLinks | null>(null);
  const [value, setValue] = useState<string>("");
  const [saving, setSaving] = useState(false);
  const [visible, setVisible] = useState(false);

  const shouldDisplay = (link: keyof HasSocialLinks) => {
    if (profile[link]) {
      if (links && links.length > 0) {
        return links.includes(link);
      } else {
        return true;
      }
    }

    return editable;
  };

  const hasValue = (link: keyof HasSocialLinks) => {
    return profile[link];
  };

  const handlePress = (link: keyof HasSocialLinks) => {
    if (editable) {
      setEditing(link);
      setValue(profile[link] || "");
      setVisible(true);
    } else if (profile[link]) {
      Linking.openURL(profile[link]);
    }
  };

  return (
    <>
      <View style={{ flexDirection: "row", marginVertical: 10 }}>
        {shouldDisplay("discord") && (
          <IconButton
            onPress={() => {
              handlePress("discord");
            }}
            style={{ margin: 10 }}
            content="Discord"
            name="discord"
            hoverColor="#7289da"
            color={hasValue("discord") ? "#7289da" : theme.colors.disabled}
            size={25}
          />
        )}
        {shouldDisplay("twitter") && (
          <IconButton
            onPress={() => {
              handlePress("twitter");
            }}
            style={{ margin: 10 }}
            content="Twitter"
            name="twitter"
            hoverColor="#1DA1F2"
            color={hasValue("twitter") ? "#1DA1F2" : theme.colors.disabled}
            size={25}
          />
        )}
        {shouldDisplay("linkedIn") && (
          <IconButton
            onPress={() => {
              handlePress("linkedIn");
            }}
            style={{ margin: 10 }}
            content="LinkedIn"
            name="linkedin"
            hoverColor="#0072b1"
            color={hasValue("linkedIn") ? "#0072b1" : theme.colors.disabled}
            size={25}
          />
        )}
        {shouldDisplay("github") && (
          <IconButton
            onPress={() => {
              handlePress("github");
            }}
            style={{ margin: 10 }}
            content="Github"
            name="github"
            hoverColor="#24292f"
            color={hasValue("github") ? "#24292f" : theme.colors.disabled}
            size={25}
          />
        )}
      </View>
      {editable ? (
        <Dialog
          title={`Update ${editing}`}
          visible={visible}
          onDismiss={() => {
            setVisible(false);
          }}
        >
          <View style={{ marginTop: 10, minWidth: 300 }}></View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              flex: 1,
              marginBottom: 10,
            }}
          >
            <TextInput
              value={value}
              style={{ flex: 1, width: "100%", marginBottom: 0 }}
              onChangeText={setValue}
              placeholder={`${editing} link`}
            />
            <IconButton
              style={{ marginLeft: 10, alignSelf: "center" }}
              content={`Go to ${clean(value)}`}
              color={theme.colors.disabled}
              hoverColor={theme.colors.primary}
              size={20}
              onPress={() => {
                Linking.openURL(value);
              }}
              name="external-link-alt"
            />
          </View>
          <Button
            loading={saving}
            disabled={value.length < 3}
            label="save"
            onPress={async () => {
              if (editable) {
                setSaving(true);
                await props.onEdit({
                  [editing]: clean(value),
                });
                setSaving(false);
                setVisible(false);
                setEditing(null);
              }
            }}
          ></Button>
        </Dialog>
      ) : null}
    </>
  );
};

const clean = (val: string) => {
  val = val.trim();
  if (!val.match(/https?/)) {
    val = "https://" + val;
  }

  return val;
};
