import { getApp, UserRepo } from "@opencraft/common";
import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  GoogleAuthProvider,
  linkWithCredential,
  signInAnonymously,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  UserCredential,
} from "firebase/auth";
import { genNonce, signInWithMetamask } from "../util/api";

// const provider = new GoogleAuthProvider();

// TODO: do we want to set this somehow?
// fbauth.languageCode = "en";

export async function authWithEmail({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  const { auth } = getApp();

  if (auth.currentUser) {
    if (!auth.currentUser.isAnonymous) {
      if (auth.currentUser.email === email) {
        return await UserRepo.get(auth.currentUser.uid);
      }
      throw new Error("already logged in");
    }

    const credential = EmailAuthProvider.credential(email, password);

    console.log("linking with credential");
    try {
      const result = await linkWithCredential(auth.currentUser, credential);
      await UserRepo.update({
        id: result.user.uid,
        email: result.user.email,
      });
      const ocUser = await UserRepo.get(result.user.uid);
      return ocUser;
    } catch (e) {
      console.log("error", e.code);
      await auth.signOut();
      if (e.code === "auth/network-request-failed") {
        const result = await signInWithEmailAndPassword(auth, email, password);
        const user = result.user;
        const ocUser = await UserRepo.get(user.uid);

        return ocUser;
      }
    }
  }

  try {
    console.log("creating user");

    const result = await createUserWithEmailAndPassword(auth, email, password);
    // Signed in
    const user = result.user;

    const ocUser = await UserRepo.create({
      id: user.uid,
      email: user.email,
    });
    return ocUser;
  } catch (e) {
    console.log("failed", e.code);
    if (
      e.code === "auth/network-request-failed" ||
      e.code === "auth/email-already-in-use"
    ) {
      console.log("failed to create user, signing in");
      const result = await signInWithEmailAndPassword(auth, email, password);
      const user = result.user;
      const ocUser = await UserRepo.get(user.uid);

      return ocUser;
    }
  }

  // ...

  // signInWithPopup(fbauth, provider)
  //   .then((result) => {
  //     // This gives you a Google Access Token. You can use it to access the Google API.
  //     const credential = GoogleAuthProvider.credentialFromResult(result);
  //     const token = credential.accessToken;
  //     // The signed-in user info.
  //     const user = result.user;
  //     // ...
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //     // Handle Errors here.
  //     const errorCode = error.code;
  //     const errorMessage = error.message;
  //     // The email of the user's account used.
  //     const email = error.customData.email;
  //     // The AuthCredential type that was used.
  //     const credential = GoogleAuthProvider.credentialFromError(error);
  //     // ...
  //   });
}

export async function authWithgoogle() {
  const { auth } = getApp();

  const result = await signInWithPopup(auth, new GoogleAuthProvider());
  const credential = GoogleAuthProvider.credentialFromResult(result);
  const token = credential.accessToken;
  // The signed-in user info.
  const user = result.user;

  const ocUser = await UserRepo.create({
    id: user.uid,
    email: user.email,
  });

  return ocUser;
}

export async function signout() {
  const { auth } = getApp();
  return signOut(auth);
}

export async function authAnon() {
  const { auth } = getApp();
  const result = await signInAnonymously(auth);

  return getOrCreateUser(result);
}

export async function metamaskConnection() {
  // @ts-ignore
  const accounts = await ethereum.request({
    method: "eth_accounts",
  });

  console.log(accounts);

  return accounts;
}

export async function metamaskRequestConnection() {
  // @ts-ignore
  const accounts = await ethereum.request({
    method: "eth_requestAccounts",
  });

  return accounts;
}

export async function authWithMetamask(address: string) {
  const { auth } = getApp();

  if (auth.currentUser != null && auth.currentUser.isAnonymous) {
    // is anon, signout
    await auth.signOut();
  }
  // https://eliteionic.com/tutorials/creating-web3-login-with-ethereum-metamask-firebase-auth/#setting-up-firebasefirestoreauthentication
  // @ts-ignore
  /// https://www.youtube.com/watch?v=vhUjCLYlnMM

  const nonceResult = await genNonce({
    address: address,
  });
  console.log("generate nonce", nonceResult);

  // @ts-ignore
  const signature = await ethereum.request({
    method: "personal_sign",
    params: [`0x${toHex(nonceResult.data.nonce)}`, address],
  });

  const signInResult = await signInWithMetamask({
    address: address,
    signature,
  });

  const customTokenResult = await signInWithCustomToken(
    auth,
    signInResult.data.token
  );

  return getOrCreateUser(customTokenResult);
}

async function getOrCreateUser(credential: UserCredential) {
  const { user } = credential;

  try {
    let ocUser = await UserRepo.get(user.uid);
    if (!ocUser) {
      console.log("no user");
      ocUser = await UserRepo.create({
        id: user.uid,
        email: user.email,
      });
    }
    return ocUser;
  } catch (e) {
    console.log("no user", e);
    const ocUser = await UserRepo.create({
      id: user.uid,
      email: user.email,
    });

    return ocUser;
    // no user
  }
}

function toHex(stringToConvert: string) {
  return stringToConvert
    .split("")
    .map((c) => c.charCodeAt(0).toString(16).padStart(2, "0"))
    .join("");
}
