import { Ionicons } from "@expo/vector-icons";
import {
  ApplicationRepo,
  CompanyRepo,
  getApplicationID,
  Gig,
} from "@opencraft/common";
import { formatRelative } from "date-fns";
import React, { useState } from "react";
import { View } from "react-native";
import { useNav } from "../app/navigation";
import { AppText as Text } from "./StyledText";
import { TokenMash } from "../components/TokenMash";
import { useCurrentUser } from "../util/auth";
import { useBreakpoints } from "../util/hooks";
import { useRepo } from "../util/store";
import { useTheme } from "../util/theme";
import { Button } from "./Button";
import { Card } from "./Card";
import { CompanyPreview } from "./CompanyPreview";
import { Dialog } from "./Dialog";
import { Loader } from "./Loader";

type Props = {
  gig: Gig;
  hideCompany?: boolean;
  isOwner?: boolean;
};

export const JobRow = ({ gig, hideCompany, isOwner }: Props) => {
  const theme = useTheme();
  const user = useCurrentUser();

  const { useGet } = useRepo(CompanyRepo);
  const { create, useGet: getApplication } = useRepo(ApplicationRepo);
  const nav = useNav("App");

  const [loadingCompany, company] = useGet(gig.owner);
  const [isModalVisible, setModalVisible] = useState(false);
  const [applying, setApplying] = useState(false);
  const [applied, setApplied] = useState(false);

  const applicationId = getApplicationID(user.id, gig.id);

  const [loadingApplication, application] = getApplication(applicationId);

  const [mobile, tablet] = useBreakpoints();

  const hasApplied = () => {
    return applied || application != null;
  };

  return (
    <Card>
      <View
        style={[
          { flex: 1 },
          tablet
            ? {
                flexDirection: "row",
                justifyContent: "space-between",
                marginVertical: theme.spacing.gutter,
                paddingBottom: theme.spacing.gutter,
              }
            : {},
        ]}
      >
        {company && !hideCompany && !tablet && (
          <View
            style={
              tablet && {
                borderLeftWidth: 1,
                borderLeftColor: theme.colors.background,
                paddingHorizontal: theme.spacing.padding,
                paddingBottom: theme.spacing.padding,
              }
            }
          >
            <CompanyPreview company={company} />
          </View>
        )}

        <View
          style={{
            flex: 1,
            paddingRight: !tablet || hideCompany ? 0 : theme.spacing.gutter,
            marginTop: tablet ? 0 : 10,
          }}
        >
          <Text
            onPress={() => {
              console.log("nav to gig", gig.owner, gig.id);
              nav.navigate("App", {
                screen: "GigDetail",
                params: {
                  companyId: gig.owner,
                  id: gig.id,
                },
              });
            }}
            style={{
              fontSize: 24,
              fontWeight: "bold",
              marginBottom: 10,
            }}
          >
            {gig.title}
          </Text>
          <View
            style={{ flexDirection: "row", marginBottom: theme.spacing.gutter }}
          >
            <Text
              style={{
                backgroundColor:
                  gig.status === "open"
                    ? theme.colors.success
                    : theme.colors.warn,
                paddingHorizontal: 5,
                paddingVertical: 2,
                fontWeight: "bold",
                borderRadius: theme.roundness,
                alignSelf: "flex-start",
                marginRight: 5,
              }}
            >
              {gig.status}
            </Text>
            {gig.role && (
              <Text
                style={{
                  backgroundColor: theme.colors.primary,
                  paddingHorizontal: 5,
                  paddingVertical: 2,
                  fontWeight: "bold",
                  borderRadius: theme.roundness,
                  alignSelf: "flex-start",
                }}
              >
                {gig.role}
              </Text>
            )}
          </View>
          <Text
            style={{
              flex: 1,
              flexWrap: "wrap",
              marginBottom: theme.spacing.gutter,
            }}
          >
            {gig.description}
          </Text>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              alignSelf: "flex-start",
              marginBottom: 5,
              display: "flex",
            }}
          >
            <Ionicons
              name="logo-bitcoin"
              size={20}
              style={{ marginRight: 5 }}
              color={theme.colors.disabled}
            />
            <Text style={{ marginRight: 2 }}>payable in</Text>
            <TokenMash tokens={gig.currencies} />
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              alignSelf: "flex-start",
              marginBottom: 5,
              display: "flex",
            }}
          >
            <Ionicons
              name="calendar-outline"
              size={20}
              style={{ marginRight: 5 }}
              color={theme.colors.disabled}
            />

            <Text style={{ fontWeight: "bold" }}>
              {formatRelative(gig.createdAt.toMillis(), new Date())}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              alignSelf: "flex-start",
              marginBottom: 5,
              display: "flex",
            }}
          >
            <Ionicons
              name="location-outline"
              size={20}
              style={{ marginRight: 5 }}
              color={theme.colors.disabled}
            />
            <Text style={{ fontWeight: "bold" }}>{gig.location}</Text>
          </View>
          {!isOwner && (
            <Button
              onPress={() => {
                setModalVisible(true);
              }}
              color={hasApplied() ? theme.colors.success : theme.colors.primary}
              style={{ alignSelf: "flex-end" }}
              format={hasApplied() ? "outline" : "filled"}
              label={hasApplied() ? "Applied" : "Apply"}
              disabled={hasApplied()}
            />
          )}
        </View>

        {company && !hideCompany && tablet && (
          <View
            style={
              tablet && {
                borderLeftWidth: 1,
                borderLeftColor: theme.colors.background,
                paddingHorizontal: theme.spacing.padding,
                paddingBottom: theme.spacing.padding,
              }
            }
          >
            <CompanyPreview company={company} />
          </View>
        )}

        <Dialog
          title="Are you sure you want to apply?"
          visible={isModalVisible}
          onDismiss={() => {
            setModalVisible(false);
          }}
        >
          <Loader visible={applying} />

          <Text>
            {company && company.name} will be notified of your application and
            will message you if they feel that it's a good fit.
          </Text>

          <View
            style={{
              flexDirection: "row-reverse",
              marginTop: theme.spacing.gutter,
            }}
          >
            <Button
              label="apply"
              size="small"
              onPress={async () => {
                const id = getApplicationID(user.id, gig.id);
                setApplying(true);
                const result = await create({
                  id,
                  userId: user?.id,
                  gigId: gig.id,
                });
                setModalVisible(false);
                setApplying(false);
                setApplied(true);
              }}
            ></Button>
            <Button
              size="small"
              onPress={() => {
                setModalVisible(false);
              }}
              style={{ marginRight: 10 }}
              color={theme.colors.accent}
              label="cancel"
            ></Button>
          </View>
        </Dialog>
      </View>
    </Card>
  );
};

/**
 *   <Popable content={"Anon OK"} action="hover">
                  <FontAwesome5
                    color={theme.colors.disabled}
                    name="user-secret"
                    size={20}
                  />
                </Popable>
 */
