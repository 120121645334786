import React, { useState } from "react";
import {
  ActivityIndicator,
  Image,
  StyleSheet,
  Text,
  TextInput,
  View,
} from "react-native";
import { useNav } from "../app/navigation";

import "@expo/match-media";
import { FontAwesome } from "@expo/vector-icons";
import { Picker } from "@react-native-picker/picker";
import { LinearGradient } from "expo-linear-gradient";
import { Button } from "../components/Button";
import { Fonts, Roundness, useTheme } from "../util/theme";

import { Role, SettingsRepo, UserRepo } from "@opencraft/common";
import { authAnon } from "../app/auth";
import { useLogin } from "../util/auth";
import { useBreakpoints } from "../util/hooks";
import { useRepo } from "../util/store";

export const Splash = () => {
  const nav = useNav("Splash");

  const theme = useTheme();

  const [all, tablet, medium, laptop] = useBreakpoints();

  const settingsRepo = useRepo(SettingsRepo);

  const [loading, settings] = settingsRepo.useGet(
    `app${process.env.NODE_ENV === "development" ? "-dev" : ""}`
  );

  const [email, setEmail] = useState("");
  const [role, setRole] = useState<Role>(Role.FREELANCER);
  const [submitting, setSubmitting] = useState(false);
  const [doneInSession, setDone] = useState(false);

  const [loadingUser, user] = useLogin();
  const repo = useRepo(UserRepo);

  const done =
    doneInSession || (user && user.waitlistEmail && user.waitlistRole);

  return (
    <View style={S.container}>
      <LinearGradient
        start={{
          x: 0.1,
          y: 0.1,
        }}
        end={{
          x: 0.9,
          y: 0.9,
        }}
        style={[S.page]}
        colors={["#436073", theme.colors.background]}
      >
        {settings && settings.live && (
          <View
            style={{
              position: "absolute",
              top: theme.spacing.margin,
              right: theme.spacing.margin,
            }}
          >
            <Button
              format="outline"
              color={theme.colors.primary}
              label="Sign In"
              onPress={() => {
                nav.navigate("SignIn");
              }}
            />
          </View>
        )}
        <View
          style={{
            position: "absolute",
            left: 0,
            bottom: 0,
            flex: 1,
            zIndex: 0,
          }}
        >
          <Image
            resizeMode="cover"
            style={{ width: 500, height: 500, flex: 1 }}
            source={require("../../assets/wizard.png")}
          />
        </View>
        <Text
          style={[
            S.title,
            { borderWidth: 10, borderColor: theme.colors.primary },
            laptop ? S.titleLarge : {},
          ]}
        >
          Open<Text style={{ color: theme.colors.primary }}>/</Text>Craft
        </Text>
        <Text
          style={[
            S.subtitle,
            laptop ? S.subtitleLarge : {},
            {
              padding: theme.spacing.gutter,
            },
          ]}
        >
          The best marketplace for freelancers who accept crypto.{" "}
          {!settings ||
            (!settings.live &&
              `Currently, by
          invitation only. Leave your email to receive updates and join the
          waitlist.`)}
        </Text>

        {loadingUser ? <ActivityIndicator /> : null}

        {settings && settings.live ? (
          <View
            style={[
              S.buttonContainer,
              medium && {
                flexDirection: "row",
              },
            ]}
          >
            <Button
              format={tablet ? "outline" : "filled"}
              color={theme.colors.primary}
              label="Find Gigs"
              onPress={() => {
                nav.navigate("App", {
                  screen: "JobSearch",
                });
              }}
            />
            <View style={[medium ? { width: 20 } : { height: 20 }]}></View>
            <Button
              format={tablet ? "outline" : "filled"}
              color={theme.colors.primary}
              label="Find Talent"
              onPress={() => {
                nav.navigate("App", {
                  screen: "ProfileSearch",
                });
              }}
            />
          </View>
        ) : (
          <>
            {done && !loadingUser && (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 60,
                }}
              >
                <FontAwesome
                  size={26}
                  name="check-circle-o"
                  color={theme.colors.accent}
                  style={{ marginRight: 10 }}
                />
                <Text style={{ color: "white", fontSize: 18, maxWidth: 350 }}>
                  Thank you for joining the waitlist. We'll be in touch.
                </Text>
              </View>
            )}
            {!done && !loadingUser ? (
              <View style={[S.buttonInput, medium ? S.buttonInputLarge : {}]}>
                <TextInput
                  value={email}
                  onChangeText={setEmail}
                  placeholder="email"
                  style={[S.emailInput, medium ? S.emailInputLarge : {}]}
                />
                <Picker
                  onValueChange={(res) => {
                    setRole(res);
                  }}
                  selectedValue={role}
                  style={[
                    S.pickerStyle,
                    { backgroundColor: theme.colors.accent },
                    medium ? S.pickerLarge : {},
                  ]}
                >
                  <Picker.Item
                    label="I'm a Freelancer"
                    value={Role.FREELANCER}
                  />
                  <Picker.Item
                    label="I Represent a Project"
                    value={Role.PROJECT_OWNER}
                  />
                </Picker>
                <FontAwesome
                  size={16}
                  name="caret-down"
                  style={[
                    { position: "absolute", color: "white" },
                    medium
                      ? { right: 30, top: 30, bottom: "auto" }
                      : {
                          right: 30,
                          bottom: 30,
                        },
                  ]}
                />
              </View>
            ) : null}
            {!done && !loadingUser && (
              <View style={S.buttonContainer}>
                <View style={{ width: 20 }}></View>

                <Button
                  loading={submitting}
                  format="outline"
                  label="Submit"
                  color={theme.colors.primary}
                  onPress={async () => {
                    setSubmitting(true);
                    if (!user) {
                      const user = await authAnon();
                      await repo.update(user.id, {
                        waitlistEmail: email,
                        waitlistRole: role,
                      });
                    } else {
                      await repo.update(user.id, {
                        waitlistEmail: email,
                        waitlistRole: role,
                      });
                    }

                    setSubmitting(false);
                    setDone(true);
                  }}
                />
                {process.env.NODE_ENV === "development" && (
                  <Button
                    style={{
                      marginLeft: 20,
                      marginTop: 20,
                      alignSelf: "center",
                    }}
                    loading={submitting}
                    format="filled"
                    label="Go to dashboard"
                    color={theme.colors.primary}
                    onPress={async () => {
                      nav.navigate("App", {
                        screen: "ProfileSearch",
                      });
                    }}
                  />
                )}
              </View>
            )}
          </>
        )}
      </LinearGradient>
    </View>
  );
};

const S = StyleSheet.create({
  container: {
    flex: 1,
  },

  buttonContainer: {
    flexDirection: "column",
    marginTop: 40,
  },

  buttonInput: {
    marginTop: 40,
    flexDirection: "column",
    position: "relative",
    padding: 20,
    width: "100%",
    maxWidth: 500,
  },

  buttonInputLarge: {
    flexDirection: "row",
  },

  nav: {
    backgroundColor: "white",
    height: 100,
    flexDirection: "row-reverse",
    alignItems: "center",
    paddingHorizontal: 20,
    width: "70%",
    maxWidth: 400,
  },

  emailInput: {
    backgroundColor: "white",
    borderTopLeftRadius: Roundness.roundness,
    borderTopRightRadius: Roundness.roundness,
    padding: 10,
    fontSize: 16,
    flex: 1,

    borderWidth: 0,
  },

  emailInputLarge: {
    flex: 3,
    borderTopLeftRadius: Roundness.roundness,
    borderBottomLeftRadius: Roundness.roundness,
    borderTopRightRadius: 0,
  },

  pickerStyle: {
    padding: 10,
    fontSize: 16,
    borderWidth: 0,
    color: "white",
    paddingRight: 30,
    flex: 1,
    appearance: "none",
    borderBottomRightRadius: Roundness.roundness,
    borderBottomLeftRadius: Roundness.roundness,
  },

  pickerLarge: {
    borderTopRightRadius: Roundness.roundness,
    borderBottomRightRadius: Roundness.roundness,
    borderBottomLeftRadius: 0,
  },

  title: {
    fontSize: 40,
    marginBottom: 0,
    paddingHorizontal: 20,
    paddingVertical: 10,
    textAlign: "center",
    color: "white",
    fontFamily: Fonts.fonts.title,
  },

  titleLarge: {
    fontSize: 80,
    marginBottom: 20,
  },

  subtitle: {
    fontSize: 16,
    lineHeight: 20,

    color: "white",
    maxWidth: 800,
    textAlign: "center",
    zIndex: 10,
  },

  subtitleLarge: {
    fontSize: 24,
    lineHeight: 28,
  },

  page: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    position: "relative",
  },
});
