import React, { useEffect, useState } from "react";
import { RootNavParams, useNav } from "../app/navigation";

import { useTheme } from "../util/theme";

import { ProfileRepo } from "@opencraft/common";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { OnboardingView } from "../components/OnboardingView";
import { TextInput } from "../components/TextInput";
import { useCurrentUser, useOrEstablishProfile } from "../util/auth";
import { useRepo } from "../util/store";

type Props = NativeStackScreenProps<RootNavParams, "OnboardingFreelancerName">;

export const OnboardingFreelancerName = (props: Props) => {
  const { step } = props.route.params;
  const nav = useNav("OnboardingFreelancerName");
  const theme = useTheme();
  const [handle, setHandle] = useState("");

  const currentUser = useCurrentUser();

  const [loading, profile] = useOrEstablishProfile();
  const { update } = useRepo(ProfileRepo);

  useEffect(() => {
    if (profile) {
      setHandle(profile.handle);
    }
  }, [profile]);

  return (
    <OnboardingView
      step={parseInt(step)}
      title="Give Yourself a Handle"
      description="This will display as your name on your profile. Both your
  real name or an anon handle works."
      onNext={async () => {
        update(profile.id, {
          handle,
        });

        nav.navigate("OnboardingFreelancerCurrencies", {
          step: `${parseInt(step) + 1}`,
        });
      }}
    >
      <TextInput
        value={handle}
        onChangeText={setHandle}
        style={{
          marginVertical: 50,
          // @ts-ignore
          outlineStyle: "none",
          fontSize: 24,
          borderBottomWidth: 3,
          borderBottomColor: theme.colors.accent,
          paddingVertical: 10,
        }}
        placeholder="handle"
      ></TextInput>
    </OnboardingView>
  );
};
