import React, { useContext } from "react";

type ThemeFonts = {
  fonts: {
    title: string;
    body: string;
  };
};

type ThemeRoundness = {
  roundness: number;
};

type ThemeSpacing = {
  spacing: {
    gutter: number;
    padding: number;
    margin: number;
  };
  pageSize: number
};



type Theme = {
  colors: {
    primary: string;
    disabled: string;
    accent: string;
    info: string;
    warn: string;
    text: string;
    textInverse: string;
    success: string;
    error: string;
    border: string;
    base: string;
    background: string;
    ambientBG: string;
    hoverAccent: string;
  };
} & ThemeFonts &
  ThemeRoundness &
  ThemeSpacing;

export const Fonts: ThemeFonts = {
  fonts: {
    title: "OpenSans_800ExtraBold",
    body: "OpenSans_400Regular",
  },
};

export const Roundness: ThemeRoundness = {
  roundness: 4,
};

export const Spacing: ThemeSpacing = {
  spacing: {
    gutter: 20,
    padding: 20,
    margin: 40,
  },
  pageSize: 1100
};

const LightTheme: Theme = {
  ...Fonts,
  ...Roundness,
  ...Spacing,
  colors: {
    primary: "#4F6BFF",
    disabled: "#cecece",
    accent: "#FB5A4F",
    info: "#4F6BFF",
    text: "black",
    textInverse: "white",
    warn: "yellow",
    error: "#FB5A4F",
    success: "green",
    border: "#cecece",
    base: "rgb(242, 242, 242)",
    ambientBG: "",
    background: "white",
    hoverAccent: "#FB5A4F",
  },
};

const DarkTheme: Theme = {
  ...Fonts,
  ...Roundness,
  ...Spacing,
  colors: {
    primary: "rgb(0, 155, 125)",
    disabled: "#cecece",
    accent: "rgb(0, 155, 125)",
    info: "#4F6BFF",
    warn: "yellow",
    text: "white",
    textInverse: "black",
    error: "#FB5A4F",
    success: "green",
    border: "black",
    base: "rgba(65, 65, 74, 1)",
    ambientBG: "rgb(35, 35, 72)",
    background: "rgb(11, 18, 43)",
    hoverAccent: "black",
  },
};

export const getTheme = (type: "light" | "dark") => {
  return type === "light" ? LightTheme : DarkTheme;
};

export const ThemeContext = React.createContext(LightTheme);

export const useTheme = () => {
  return useContext(ThemeContext);
};
