import React from "react";
import { ActivityIndicator, View } from "react-native";
type Props = {
  visible: boolean;
};

export const Loader = ({ visible }: Props) => {
  if (!visible) {
    return null;
  }

  return (
    <View
      style={{
        position: "absolute",

        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, .5)",
        zIndex: 10,
      }}
    >
      <ActivityIndicator />
    </View>
  );
};

export const loading = (...loading: boolean[]) => {
  for (let i = 0; i < loading.length; i++) {
    if (loading[i]) {
      return true;
    }
  }

  return false;
};
