import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { RootNavParams, useNav } from "../app/navigation";

import { useTheme } from "../util/theme";

import { ProfileRepo } from "@opencraft/common";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { OnboardingView } from "../components/OnboardingView";
import { CurrencySelect } from "../components/Selects";
import { useOrEstablishProfile } from "../util/auth";
import { useRepo } from "../util/store";

type Props = NativeStackScreenProps<
  RootNavParams,
  "OnboardingFreelancerCurrencies"
>;

export const OnboardingFreelancerCurrencies = (props: Props) => {
  const { step } = props.route.params;
  const nav = useNav("OnboardingFreelancerCurrencies");
  const theme = useTheme();

  const [openToAll, setOpenToAll] = useState(false);
  const [values, setValues] = useState<string[]>([]);

  const [loading, profile] = useOrEstablishProfile();
  const { update } = useRepo(ProfileRepo);

  useEffect(() => {
    if (profile && profile.preferredCurrencies) {
      setValues(profile.preferredCurrencies);
    }
  }, [profile]);

  return (
    <OnboardingView
      step={parseInt(step)}
      title="Which Currencies Will You Accept?"
      description="On opencraft, rates are negotiated and paid in crypto. Typically most folks are willing to accept USDC. Let us project owners know if you'd also be open to taking BTC, ETH or any other token."
      onNext={() => {
        update(profile.id, {
          preferredCurrencies: values,
        });

        nav.navigate("OnboardingFreelancerPhoto", {
          step: `${parseInt(step) + 1}`,
        });
      }}
    >
      <View
        style={{
          paddingVertical: theme.spacing.gutter,
          marginTop: theme.spacing.margin,
        }}
      >
        <CurrencySelect
          style={{
            backgroundColor: theme.colors.ambientBG,
          }}
          value={values}
          // placeholder="Select preferred currencies"
          onChange={(result) => {
            if (Array.isArray(result)) {
              setValues(result);
            }
          }}
        />
      </View>

      {/* <View style={{ flexDirection: "row" }}>
        <Checkbox
          color={theme.colors.accent}
          value={openToAll}
          onChange={(e) => {
            setOpenToAll(e.nativeEvent.value);
          }}
          style={{ marginRight: 5 }}
        />
        <Text>I'm open to payment in most or all currencies.</Text>
      </View> */}
    </OnboardingView>
  );
};
