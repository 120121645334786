import React, { useState } from "react";
import { RootNavParams, useNav } from "../app/navigation";

import { useTheme } from "../util/theme";

import { Company, CompanyRepo } from "@opencraft/common";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { OnboardingView } from "../components/OnboardingView";
import { TextInput } from "../components/TextInput";
import { Preload } from "../util/Preloadable";
import { useRepo } from "../util/store";

type Props = NativeStackScreenProps<RootNavParams, "OnboardingProjectBio">;

type PreloadedProps = {
  company?: Company;
};

export const OnboardingProjectBio = Preload<Props, PreloadedProps>(
  (props) => {
    const { step } = props.route.params;
    const nav = useNav("OnboardingProjectBio");
    const theme = useTheme();

    const company = props.preloaded.company;

    const [description, setDescription] = useState(company?.description);

    const { update } = useRepo(CompanyRepo);

    return (
      <OnboardingView
        step={parseInt(step)}
        title="Share a little bit about your organization"
        description="This is an open space to explain a little bit about your org and team. You'll have an opportunity to post individual gigs & projects later, so this can stay pretty high level."
        onNext={() => {
          if (company) {
            update(company.id, {
              description,
            });
          }

          nav.navigate("OnboardingProjectCurrencies", {
            step: `${parseInt(step) + 1}`,
          });
        }}
      >
        <TextInput
          value={description}
          multiline
          numberOfLines={5}
          style={{
            marginVertical: 20,
            backgroundColor: theme.colors.ambientBG,
          }}
          placeholder="Description"
          onChangeText={setDescription}
        />
      </OnboardingView>
    );
  },
  async (props, context) => {
    const co = await CompanyRepo.query({
      where: [
        {
          field: "owner",
          op: "==",
          value: context.currentUser?.id,
        },
      ],
    });

    return {
      company: co.length > 0 ? co[0] : undefined,
    };
  }
);
