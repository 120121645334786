import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { useNav } from "../app/navigation";

import { LinearGradient } from "expo-linear-gradient";
import { Button } from "../components/Button";
import { useCurrentUser } from "../util/auth";
import { Fonts, useTheme } from "../util/theme";

export const Home = () => {
  const nav = useNav("Home");
  const theme = useTheme();
  const currentUser = useCurrentUser();

  return (
    <View
      style={[
        S.container,
        {
          backgroundColor: theme.colors.ambientBG,
        },
      ]}
    >
      <View style={S.nav}>
        <View>
          <Button
            label="Login"
            onPress={() => {
              nav.navigate("SignIn");
            }}
          />
        </View>
      </View>
      <LinearGradient
        start={{
          x: 0.1,
          y: 0.1,
        }}
        end={{
          x: 0.9,
          y: 0.9,
        }}
        style={S.page}
        colors={["#0029FF", "#4F6BFF"]}
      >
        <Text style={S.title}>Open/craft</Text>
        <Text style={S.subtitle}>
          The best marketplace for freelancers who accept crypto
        </Text>
        <View style={S.buttonContainer}>
          <Button
            format="outline"
            label="I'm a Freelancer"
            onPress={() => {
              nav.navigate("OnboardingFreelancerStart", {
                step: "1",
              });
            }}
          />
          <View style={{ width: 20 }}></View>
          <Button
            format="outline"
            label="I'm a Project"
            onPress={() => {
              if (currentUser != null) {
                nav.navigate("OnboardingProjectStart", {
                  step: "1",
                });
              } else {
                nav.navigate("OnboardingProjectSignIn", {
                  step: "1",
                });
              }
            }}
          />
        </View>
      </LinearGradient>
    </View>
  );
};

const S = StyleSheet.create({
  container: {
    flex: 1,
  },

  buttonContainer: {
    flexDirection: "row",
    marginTop: 40,
  },

  nav: {
    height: 80,

    justifyContent: "space-between",
    alignItems: "center",
    shadowColor: "black",
    shadowRadius: 2,
    shadowOffset: {
      height: 0,
      width: 0,
    },
    // borderBottomWidth: 1,
    // borderBottomColor: theme.colors.disabled,

    flexDirection: "row-reverse",

    paddingHorizontal: 20,
  },

  title: {
    fontSize: 80,
    marginBottom: 20,
    textAlign: "center",
    color: "white",
    fontFamily: Fonts.fonts.title,
  },

  subtitle: {
    fontSize: 34,
    color: "white",
  },

  page: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
});
