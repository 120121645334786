import React from "react";
import { ScrollView, View } from "react-native";
import Modal from "react-native-modal";
import { AppText as Text } from "./StyledText";
import { useTheme } from "../util/theme";
import { useBreakpoints } from "../util/hooks";

export type DialogProps = {
  visible: boolean;
  onDismiss: () => void;
  title: string;
  children: React.ReactNode;
  scrollable?: boolean;
  Footer?: React.ReactElement;
};

export const Dialog = ({
  visible,
  onDismiss,
  title,
  children,
  scrollable,
  Footer,
}: DialogProps) => {
  const theme = useTheme();

  const [mobile, tablet] = useBreakpoints();

  return (
    <Modal
      isVisible={visible}
      animationIn="fadeIn"
      animationOut="fadeOut"
      style={{
        alignSelf: "center",
        backgroundColor: "transparent",
        minWidth: tablet ? 500 : 300,
      }}
      onBackdropPress={() => onDismiss()}
    >
      <View style={[scrollable ? { flex: 1 } : {}]}>
        <ScrollView
          style={{
            backgroundColor: theme.colors.ambientBG,
            padding: theme.spacing.gutter,
            position: "relative",
          }}
        >
          <Text style={{ fontSize: 18, fontWeight: "bold", marginBottom: 10 }}>
            {title}
          </Text>
          <View>{children}</View>
        </ScrollView>
        <View
          style={{
            backgroundColor: theme.colors.ambientBG,
            padding: theme.spacing.gutter,
            position: "relative",
          }}
        >
          {Footer}
        </View>
      </View>
    </Modal>
  );
};
