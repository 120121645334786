import React from "react";
import { RootNavParams, useNav } from "../app/navigation";

import { useTheme } from "../util/theme";

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { OnboardingView } from "../components/OnboardingView";

import { SignInLockup } from "../components/SignInLockup";
import { useOrEstablishProfile } from "../util/auth";
import { useNotify } from "../util/toast";

type Props = NativeStackScreenProps<
  RootNavParams,
  "OnboardingFreelancerSignin"
>;

export const OnboardingFreelancerSignin = (props: Props) => {
  const { step } = props.route.params;
  const nav = useNav("OnboardingFreelancerSignin");
  const theme = useTheme();

  const [_, profile] = useOrEstablishProfile();

  const notify = useNotify();

  return (
    <OnboardingView
      noAction
      step={parseInt(step)}
      title="Sign In"
      description="Last step. Log in with an email and password. If you've chosen an anon handle and avatar, don't worry, your email will be kept private."
    >
      <SignInLockup
        methods={["Email"]}
        onAuth={(res) => {
          if (res.success === false) {
            notify(res.error.message, "ERROR", "LONG");
          } else {
            console.log("authed", res);
            nav.navigate("App", {
              screen: "JobSearch",
            });
          }
        }}
      />
    </OnboardingView>
  );
};
