import { Gig, GigRepo, GigType, ProfileRepo, Query } from "@opencraft/common";
import React, { useMemo, useState } from "react";
import {
  ActivityIndicator,
  FlatList,
  ScrollView,
  StyleSheet,
  Switch,
  View,
} from "react-native";
import { Button } from "../components/Button";
import { ButtonSelect } from "../components/ButtonSelect";
import { JobRow } from "../components/JobRow";
import { ProfileCompletionCard } from "../components/ProfileCompletionCard";
import { SearchAndFilter } from "../components/SearchAndFilter";
import { CurrencySelect } from "../components/Selects";
import { Label, Title } from "../components/StyledText";
import { useCurrentUser } from "../util/auth";
import { useRepo } from "../util/store";
import { Roundness, ThemeContext, useTheme } from "../util/theme";
import { Ionicons } from "@expo/vector-icons";

const GigTypeOptions: {
  value: GigType;
  label: GigType;
}[] = Object.values(GigType).map((k) => {
  return {
    value: k,
    label: k,
  };
});

export const JobSearch = () => {
  const user = useCurrentUser();
  const { useGet } = useRepo(ProfileRepo);
  const theme = useTheme();

  const [searchTerm, setSearchTerm] = useState("");

  const [filters, setFilters] = useState<Partial<Gig>>({});

  const [query, setQuery] = useState<Query<Gig>>({
    where: [
      {
        field: "currencies",
        op: "array-contains-any",
        value: ["btc"],
      },
    ],
  });

  const gigRepo = useRepo(GigRepo);
  const [loading, gigs, updateQuery] = gigRepo.useQuery(query);
  const [allGigsLoading, allGigs] = gigRepo.useAll();

  const filteredGigs = useMemo(() => {
    if (searchTerm.length < 1 && Object.keys(filters).length == 0) {
      return allGigs;
    }

    let regex = new RegExp(searchTerm.split("").join(".*"));

    return allGigs.filter((g) => {
      // consider https://fusejs.io/examples.html#search-string-array
      let matches = true;

      if (searchTerm.length > 0) {
        if (!regex.exec(g.title + " " + g.description + " " + g.role)) {
          matches = false;
        }
      }

      if ("anonOk" in filters) {
        if (filters.anonOk != g.anonOk) {
          matches = false;
        }
      }

      if ("type" in filters) {
        if (filters.type != g.type) {
          matches = false;
        }
      }

      if ("currencies" in filters && filters.currencies.length > 0) {
        let accepts = new Set(filters.currencies);

        if (
          !g.currencies ||
          g.currencies.filter((c) => accepts.has(c)).length === 0
        ) {
          matches = false;
        }
      }

      return matches;
    });
  }, [searchTerm, allGigs, filters]);

  return (
    <ScrollView style={[S.page]}>
      <View style={{ maxWidth: theme.pageSize }}>
        <ProfileCompletionCard />

        <Title style={{ marginBottom: 20 }}>Job Search</Title>
        <SearchAndFilter
          searchTerm={searchTerm}
          scrollable={false}
          filters={filters}
          onFilterUpdate={(searchTerm, filters) => {
            setSearchTerm(searchTerm);
            setFilters(filters);
          }}
        >
          <>
            <Label>Preferred Currencies</Label>
            <CurrencySelect
              value={filters.currencies || []}
              onChange={(val) => {
                setFilters({ ...filters, currencies: val });
              }}
            />
            <ButtonSelect
              options={GigTypeOptions}
              value={filters.type}
              label="Type"
              onChange={(val) => {
                setFilters({ ...filters, type: val as GigType });
              }}
            />
            <Label>Anon OK?</Label>
            <Switch
              onValueChange={(val) => {
                setFilters({ ...filters, anonOk: val });
              }}
              value={filters.anonOk}
            />
          </>
        </SearchAndFilter>

        <View style={{ minHeight: 200 }}>
          {loading ? (
            <View style={{ justifyContent: "center", alignItems: "center" }}>
              <ActivityIndicator />
            </View>
          ) : (
            <FlatList
              ListEmptyComponent={() => {
                return (
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Ionicons
                      color={theme.colors.text}
                      name="search"
                      size={100}
                    />
                    <Title style={{ maxWidth: 500, textAlign: "center" }}>
                      No gigs found matching your filters. Consider broadening
                      your search.
                    </Title>
                    {Object.keys(filters).length > 0 && (
                      <Button
                        style={{ alignSelf: "center", marginTop: 20 }}
                        label="Clear Filters"
                        onPress={() => setFilters({})}
                      />
                    )}
                  </View>
                );
              }}
              data={filteredGigs}
              renderItem={({ item }) => {
                return <JobRow gig={item} />;
              }}
            />
          )}
        </View>
      </View>
    </ScrollView>
  );
};

const S = StyleSheet.create({
  page: {
    padding: 40,
    flex: 1,
  },

  searchContent: {
    flex: 1,
    marginBottom: 40,
  },

  card: {
    backgroundColor: "white",
    maxWidth: 1100,
    padding: 20,
    borderRadius: Roundness.roundness,
    shadowColor: "rgba(0, 0, 0, .5)",
    shadowRadius: 2,
    shadowOffset: {
      width: 1,
      height: 1,
    },
    marginBottom: 20,
  },
});
