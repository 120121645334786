import { getApp, USE_EMULATOR } from "@opencraft/common";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallableFromURL,
} from "firebase/functions";

const functions = getFunctions(getApp().app, "us-central1");

console.log(process.env.NODE_ENV);

const BASE_URL =
  process.env.NODE_ENV === "development" && !USE_EMULATOR
    ? "http://localhost:5001/opencraft-355017/us-central1/"
    : "https://tryopencraft.com/";

if (USE_EMULATOR) {
  console.log("connecting to emulator");
  connectFunctionsEmulator(functions, "localhost", 5001);
}

console.log("functions running on BASEURL", BASE_URL);

export const signInWithMetamask = httpsCallableFromURL<
  {
    address: string;
    signature: string;
  },
  {
    token: string;
    id: string;
  }
>(functions, `${BASE_URL}signInWithMetamask`);

export const genNonce = httpsCallableFromURL<
  {
    address: string;
  },
  {
    nonce: string;
  }
>(functions, `${BASE_URL}genNonce`);
