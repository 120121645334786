import { CompanyRepo, GigRepo } from "@opencraft/common";
import { RouteProp, useRoute } from "@react-navigation/native";
import React from "react";
import { FlatList, ScrollView, StyleSheet, View } from "react-native";
import { AppNavParams, useNav } from "../app/navigation";
import { Button } from "../components/Button";
import { Card } from "../components/Card";
import { CompanyPreview } from "../components/CompanyPreview";
import { IconButton } from "../components/IconButton";
import { JobRow } from "../components/JobRow";
import { Loader, loading } from "../components/Loader";
import { Title } from "../components/StyledText";
import { useRequiredCurrentUser } from "../util/auth";
import { useRepo } from "../util/store";
import { useTheme } from "../util/theme";
import { Ionicons } from "@expo/vector-icons";

export const CompanyDetail = () => {
  const user = useRequiredCurrentUser();
  const theme = useTheme();

  const route = useRoute<RouteProp<AppNavParams, "CompanyDetail">>();

  const { id } = route.params;

  const nav = useNav("App");

  const companyRepo = useRepo(CompanyRepo);
  const gigRepo = useRepo(GigRepo);
  const [loadingCompany, company] = companyRepo.useGet(id);

  const [loadingGigs, gigs] = gigRepo.useQuery({
    where: [
      {
        field: "owner",
        op: "==",
        value: id,
      },
    ],
  });

  if (loading(loadingCompany, loadingGigs) || !company) {
    return <Loader visible />;
  }

  const isOwner = company && company.owner === user.id;

  return (
    <ScrollView style={S.page}>
      <Card style={{ position: "relative" }}>
        <View style={S.profile}>
          <CompanyPreview company={company} fullDescription width={400} />
        </View>
        {isOwner && (
          <View
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 5,
            }}
          >
            <IconButton
              color={"white"}
              hoverColor={theme.colors.primary}
              size={25}
              onPress={() => {
                nav.navigate("OnboardingProjectStart", {
                  step: "1",
                });
              }}
              content="Edit info"
              name="edit"
            />
          </View>
        )}
      </Card>
      <Button
        onPress={() => {
          nav.navigate("App", {
            screen: "CreateGig",
            params: {
              companyId: company.id,
            },
          });
        }}
        style={{ alignSelf: "flex-start", marginBottom: theme.spacing.gutter }}
        label="Create New Gig"
      />
      <Card>
        <Title>Posted Gigs</Title>
        <FlatList
          data={gigs}
          renderItem={({ item }) => {
            return <JobRow gig={item} hideCompany />;
          }}
          ListEmptyComponent={
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Ionicons
                color={theme.colors.text}
                name="hammer-outline"
                size={50}
              />
              <Title style={{ maxWidth: 500, textAlign: "center" }}>
                You can't created any gigs yet
              </Title>
              <Button
                style={{ alignSelf: "center", marginTop: 20 }}
                label="Create Gig"
                onPress={() =>
                  nav.navigate("App", {
                    screen: "CreateGig",
                    params: {
                      companyId: company.id,
                    },
                  })
                }
              />
            </View>
          }
        />
      </Card>
    </ScrollView>
  );
};

const S = StyleSheet.create({
  page: {
    padding: 40,
    flex: 1,
  },

  profile: {
    justifyContent: "center",
    alignItems: "center",
  },
});
