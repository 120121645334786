import { Company, Profile } from "@opencraft/common";
import React from "react";
import { View } from "react-native";
import { useNav } from "../app/navigation";
import { useBreakpoints } from "../util/hooks";
import { useTheme } from "../util/theme";
import { Avatar } from "./Avatar";
import { Card } from "./Card";
import { ContactButton } from "./ContactButton";
import { Pill } from "./Pill";
import { AppText as Text } from "./StyledText";

type Props = {
  profile: Profile;
  company?: Company;
};

export const ProfilePreview = ({ profile, company }: Props) => {
  const theme = useTheme();

  const nav = useNav("App");

  const [moble, tablet] = useBreakpoints();

  return (
    <Card
      style={
        tablet
          ? {
              padding: theme.spacing.padding,
              alignItems: "center",
              width: 300,
              borderWidth: 1,
              backgroundColor: theme.colors.background,

              borderRadius: theme.roundness,
              marginRight: theme.spacing.gutter,
              justifyContent: "space-between",
            }
          : {
              flex: 1,
            }
      }
    >
      <View style={{ alignItems: "center", flex: 1, display: "flex" }}>
        <Avatar
          profile={profile}
          onPress={() => {
            console.log("navigating to profile", profile.id);
            nav.navigate("App", {
              screen: "ProfileDetail",
              params: {
                id: profile.id,
              },
            });
          }}
        />
        <Text
          style={{
            textAlign: "center",
            fontWeight: "bold",
            textTransform: "uppercase",
            fontSize: 16,
            marginVertical: 5,
          }}
        >
          {profile.handle}
        </Text>
        <Pill
          style={{ alignSelf: "center", marginVertical: 10 }}
          color={theme.colors.accent}
        >
          {profile.role}
        </Pill>
        <Text>{profile.bio}</Text>
        <ContactButton profile={profile} company={company} />
      </View>
    </Card>
  );
};
