import { Ionicons } from "@expo/vector-icons";
import React, { ComponentProps, useRef } from "react";
import {
  ActivityIndicator,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from "react-native";
import { useHover } from "react-native-web-hooks";
import { useTheme } from "../util/theme";

type Props = {
  label: string;
  onPress: () => void;
  disabled?: boolean;
  size?: "standard" | "small";
  loading?: boolean;
  format?: "filled" | "outline";
  style?: StyleProp<ViewStyle>;
  color?: string;
  icon?: ValueOf<Pick<ComponentProps<typeof Ionicons>, "name">>;
};

type ValueOf<T> = T[keyof T];

export const Button = ({
  label,
  onPress,
  disabled,
  icon,
  loading,
  style,
  color,
  format = "filled",
  size = "standard",
}: Props) => {
  const theme = useTheme();

  const ref = useRef(null);

  const hovering = useHover(ref);

  return (
    <Pressable
      ref={ref}
      style={[
        S.button,
        {
          backgroundColor: color || theme.colors.primary,
        },
        disabled || loading
          ? {
              backgroundColor: theme.colors.disabled,
              cursor: "default",
            }
          : {},
        format === "outline"
          ? {
              borderColor: color,
              borderWidth: 2,
              backgroundColor: "transparent",
            }
          : {},
        hovering && !disabled
          ? format === "outline"
            ? {
                backgroundColor: color,
              }
            : {
                backgroundColor: "transparent",
                borderColor: color || theme.colors.primary,
              }
          : {},

        getContainerSizeStyle(size),
        style,
      ]}
      onPress={() => {
        if (!disabled && !loading) {
          onPress();
        }
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading && (
          <ActivityIndicator
            size={16}
            color={hovering ? color || theme.colors.primary : theme.colors.text}
            style={{ marginRight: 10 }}
          />
        )}
        {icon && !loading && (
          <Ionicons
            size={30}
            style={{ fontWeight: "bold", marginRight: 5 }}
            color={hovering ? color || theme.colors.primary : theme.colors.text}
            name={icon}
          />
        )}
        <Text
          style={[
            S.text,
            hovering && !disabled
              ? format === "outline"
                ? {
                    color: theme.colors.text,
                  }
                : {
                    color: color || theme.colors.primary,
                  }
              : format === "outline"
              ? { color: color }
              : {},
          ]}
        >
          {label}
        </Text>
      </View>
    </Pressable>
  );
};

const S = StyleSheet.create({
  button: {
    paddingVertical: 15,
    paddingHorizontal: 15,
    minWidth: 150,
    borderRadius: 2,
    borderWidth: 2,
    borderColor: "transparent",
  },

  buttonSmall: {
    minWidth: "auto",
    alignSelf: "flex-end",
    paddingVertical: 10,
  },

  outline: {
    borderWidth: 2,
    borderColor: "white",
    backgroundColor: "none",
  },

  hovering: {
    backgroundColor: "white",
  },

  hoveringOutline: {
    backgroundColor: "white",
  },

  text: {
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
});

const getContainerSizeStyle = (size: "standard" | "small") => {
  if (size === "small") {
    return S.buttonSmall;
  }

  return {};
};
