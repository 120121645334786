import React from "react";
import { RootNavParams, useNav } from "../app/navigation";

import { useTheme } from "../util/theme";

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { View } from "react-native";
import { OnboardingView } from "../components/OnboardingView";
import { SignInLockup } from "../components/SignInLockup";
import { useNotify } from "../util/toast";

type Props = NativeStackScreenProps<RootNavParams, "OnboardingFreelancerStart">;

export const OnboardingFreelancerStart = (props: Props) => {
  const { step } = props.route.params;
  const nav = useNav("OnboardingFreelancerStart");
  const theme = useTheme();

  const notify = useNotify();
  return (
    <OnboardingView
      step={parseInt(step)}
      title="Get Started Freelancing"
      description="We'll go through a few steps to create your profile. First though, we need to create an account. We recommend authenticating with Metamask, but if you prefer email, that's OK too."
      noAction
    >
      <View
        style={{
          maxWidth: 400,
          marginTop: theme.spacing.margin,
          padding: theme.spacing.padding,
          flex: 1,
          backgroundColor: theme.colors.ambientBG,
        }}
      >
        <SignInLockup
          methods={["Metamask", "Email"]}
          onAuth={(res) => {
            if (res.success === false) {
              notify(res.error.message, "ERROR", "LONG");
            } else {
              console.log("authed", res);
              nav.navigate("OnboardingFreelancerName", {
                step: `${parseInt(step) + 1}`,
              });
            }
          }}
        />
      </View>
    </OnboardingView>
  );
};
