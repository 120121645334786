import { User } from "@opencraft/common";
import React, { useEffect, useState } from "react";
import { Alert, View, StyleSheet, ActivityIndicator } from "react-native";
import { useTheme } from "../util/theme";
import {
  authWithEmail,
  authWithgoogle,
  authWithMetamask,
  metamaskConnection,
  metamaskRequestConnection,
} from "../app/auth";
import { useCurrentUser } from "../util/auth";
import { Button } from "./Button";
import { AppText as Text } from "./StyledText";
import { TextInput } from "./TextInput";
import { useNotify } from "../util/toast";
import { useNav } from "../app/navigation";
import { Ionicons } from "@expo/vector-icons";

type Props = {
  methods: ("Google" | "Email" | "Metamask")[];
  onAuth: (
    resullt: { success: true; user: User } | { success: false; error: any }
  ) => void;
};

export const SignInLockup = ({ methods, onAuth }: Props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loggingIn, setLoggingIn] = useState(false);

  const [usingMetamask, setUsingMetamask] = useState(false);
  const [checkingConnection, setCheckingConnection] = useState(true);
  const [signingNonce, setSigningNonce] = useState(false);
  const [ethAccount, setEthAccount] = useState<string | null>(null);

  const notify = useNotify();
  useEffect(() => {
    if (usingMetamask) {
      setCheckingConnection(true);
      metamaskConnection()
        .then((a) => {
          if (a && a.length > 0) {
            setEthAccount(a[0]);
          }
          setCheckingConnection(false);
        })
        .catch(() => {
          setCheckingConnection(false);
        });
    }
  }, [usingMetamask]);

  const theme = useTheme();

  const user = useCurrentUser();

  return (
    <View>
      {usingMetamask ? (
        <View>
          <View style={{ marginBottom: 20 }}>
            <Text>Connecting to Metamask is a quick two-step process.</Text>
          </View>

          <View></View>

          <View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <View
                style={{
                  height: 50,
                  width: 50,
                  borderRadius: 25,
                  borderWidth: 3,
                  borderColor: theme.colors.accent,
                  backgroundColor:
                    ethAccount != null ? theme.colors.accent : "transparent",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {checkingConnection ? (
                  <ActivityIndicator />
                ) : ethAccount != null ? (
                  <Ionicons
                    color={theme.colors.text}
                    size={30}
                    name="checkmark"
                  />
                ) : (
                  <Text>1</Text>
                )}
              </View>
              <View
                style={{
                  height: 1,
                  borderBottomWidth: 3,
                  borderBottomColor: theme.colors.accent,
                  borderStyle: "dashed",
                  flex: 1,
                }}
              ></View>
              <View style={{ flex: 2 }}>
                <Button
                  style={[
                    ethAccount != null
                      ? {
                          borderColor: theme.colors.accent,
                          borderWidth: 3,
                        }
                      : {},
                  ]}
                  disabled={ethAccount != null || checkingConnection}
                  onPress={async () => {
                    setCheckingConnection(true);
                    const accounts = await metamaskRequestConnection().catch(
                      (e) => {
                        notify("Rejected Metamask Login");
                        setUsingMetamask(false);
                        setCheckingConnection(false);
                      }
                    );

                    console.log(accounts);
                    setEthAccount(accounts[0]);
                    notify("Done! Click Sign Nonce to Log In");
                    setCheckingConnection(false);
                  }}
                  label={ethAccount != null ? "Connected" : "Connect to Site"}
                />
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <View
                style={{
                  height: 50,
                  width: 50,
                  borderRadius: 25,
                  borderWidth: 3,
                  borderColor: !ethAccount
                    ? theme.colors.disabled
                    : theme.colors.accent,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {loggingIn ? <ActivityIndicator /> : <Text>2</Text>}
              </View>
              <View
                style={{
                  height: 1,
                  borderBottomWidth: 3,
                  borderBottomColor: !ethAccount
                    ? theme.colors.disabled
                    : theme.colors.accent,
                  borderStyle: "dashed",
                  flex: 1,
                }}
              ></View>
              <View style={{ flex: 2 }}>
                <Button
                  onPress={async () => {
                    try {
                      setLoggingIn(true);
                      console.log("loogging in");
                      const result = await authWithMetamask(ethAccount);
                      setLoggingIn(false);
                      onAuth({
                        success: true,
                        user: result,
                      });
                    } catch (e) {
                      setLoggingIn(false);
                      notify("Failed to login", "ERROR", "LONG");
                      onAuth({
                        success: false,
                        error: e,
                      });
                    }
                  }}
                  disabled={!ethAccount}
                  label="Sign Nonce to Log in"
                />
              </View>
            </View>
          </View>
        </View>
      ) : (
        <>
          {methods.includes("Metamask") && (
            <Button
              label="Auth With Metamask"
              onPress={async () => {
                setUsingMetamask(true);
                // try {
                //   setLoggingIn(true);
                //   console.log("loogging in");
                //   const result = await authWithMetamask();
                //   setLoggingIn(false);
                //   onAuth({
                //     success: true,
                //     user: result,
                //   });
                // } catch (e) {
                //   setLoggingIn(false);
                //   console.error(e);
                //   onAuth({
                //     success: false,
                //     error: e,
                //   });
                // }
              }}
            />
          )}
          {methods.length > 1 && (
            <Text style={{ textAlign: "center", paddingTop: 10 }}>Or</Text>
          )}
          {methods.includes("Email") && (
            <>
              <TextInput
                style={{ marginVertical: 20, fontFamily: theme.fonts.body }}
                autoCapitalize="none"
                autoCorrect={false}
                value={email}
                placeholder="email"
                onChangeText={setEmail}
              />
              <TextInput
                style={{ marginBottom: 20, fontFamily: theme.fonts.body }}
                secureTextEntry
                autoCapitalize="none"
                autoCorrect={false}
                value={password}
                placeholder="password"
                onChangeText={setPassword}
              />
              <Button
                label="Login"
                loading={loggingIn}
                style={{ alignSelf: "flex-start" }}
                disabled={!email || !password || loggingIn}
                onPress={async () => {
                  try {
                    setLoggingIn(true);
                    const result = await authWithEmail({ email, password });
                    // const result = await auth({ email, password });
                    setLoggingIn(false);
                    onAuth({
                      success: true,
                      user: result,
                    });
                  } catch (e) {
                    setLoggingIn(false);
                    console.error(e);
                    onAuth({
                      success: false,
                      error: e,
                    });
                  }
                }}
              />
            </>
          )}

          {methods.includes("Google") && (
            <>
              {methods.length > 1 && (
                <Text style={{ textAlign: "center", padding: 10 }}>Or</Text>
              )}
              <Button
                label="Login With Google"
                onPress={async () => {
                  try {
                    await authWithgoogle();
                    // const result = await auth({ email, password });
                    Alert.alert("login successful!");
                  } catch (e) {
                    console.error(e);
                  }
                }}
              />
            </>
          )}
        </>
      )}
    </View>
  );
};
