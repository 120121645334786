import { Ionicons } from "@expo/vector-icons";
import React, { useRef } from "react";
import { Pressable } from "react-native";
import { useHover } from "react-native-web-hooks";
import { useTheme } from "../util/theme";
import {AppText as Text} from "../components/StyledText"

type Props = {
  onPress: () => void;
  icon: "person-circle-outline" | "log-out-outline" | "business-outline";
  title: string;
};

export const MenuLink = ({ onPress, icon, title }: Props) => {
  const theme = useTheme();

  const ref = useRef(null);
  const hover = useHover(ref);

  return (
    <Pressable
      ref={ref}
      style={{
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: hover ? theme.colors.accent : theme.colors.ambientBG,
        paddingHorizontal: 10,
        paddingVertical: 5,
      }}
      onPress={onPress}
    >
      <Ionicons
        color={theme.colors.text}
        style={{ marginRight: 10 }}
        size={25}
        name={icon}
      />
      <Text>
        {title}
      </Text>
    </Pressable>
  );
};
