import { getApp } from "@opencraft/common";
import React, { useState } from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";
import { useNav } from "../app/navigation";
import { Button } from "../components/Button";
import { SignInLockup as SignInBody } from "../components/SignInLockup";
import { AppText as Text, Title } from "../components/StyledText";
import { useCurrentUser, useLogout } from "../util/auth";
import { useTheme } from "../util/theme";

export const SignIn = () => {
  const nav = useNav("SignIn");

  const theme = useTheme();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loggingIn, setLoggingIn] = useState(false);

  const user = useCurrentUser();
  const logout = useLogout();

  const { auth } = getApp();

  return (
    <View style={[S.page, { backgroundColor: theme.colors.background }]}>
      <Title style={{ marginBottom: 20 }}>
        Log in to
        <Title
          style={{
            borderWidth: 6,
            borderColor: theme.colors.primary,
            paddingHorizontal: 5,
            paddingVertical: 2,
            marginLeft: 5,
            marginBottom: theme.spacing.gutter,
          }}
        >
          Open<Text style={{ color: theme.colors.primary }}>/</Text>Craft
        </Title>
      </Title>

      <View style={[S.container, { backgroundColor: theme.colors.ambientBG }]}>
        {loggingIn && (
          <View style={S.loading}>
            <ActivityIndicator />
          </View>
        )}

        <View style={{ padding: 20 }}>
          {user && !auth.currentUser.isAnonymous ? (
            <>
              <Text style={S.title}>You are Logged in...</Text>
              <Button
                label="Visit Dashboard"
                style={{ marginBottom: theme.spacing.gutter }}
                onPress={async () => {
                  nav.navigate("App", {
                    screen: "JobSearch",
                  });
                }}
              />
              <Button
                label="logout"
                onPress={async () => {
                  try {
                    logout();
                  } catch (e) {
                    console.error(e);
                  }
                }}
              />
            </>
          ) : (
            <SignInBody
              methods={["Metamask", "Email"]}
              onAuth={(res) => {
                if (res.success) {
                  nav.navigate("App", {
                    screen: "JobSearch",
                  });
                } else if (res.success === false) {
                  console.error(res.error);
                }
              }}
            />
          )}
        </View>
      </View>
    </View>
  );
};

const S = StyleSheet.create({
  title: {
    fontWeight: "bold",
    fontSize: 18,
    marginBottom: 20,
    textAlign: "center",
  },
  page: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },

  loading: {
    position: "absolute",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
    backgroundColor: "rgba(255, 255, 255, .5)",
  },

  container: {
    backgroundColor: "white",
    borderRadius: 4,

    width: "90%",

    maxWidth: 400,
    position: "relative",
  },

  input: {
    padding: 10,
    marginBottom: 10,
    borderRadius: 4,
    borderColor: "#cecece",
    borderWidth: 1,
  },
});
