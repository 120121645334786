import React, { useState } from "react";
import { ScrollView, Text, StyleSheet } from "react-native";

import { Roundness, useTheme } from "../util/theme";

import { ViewProps } from "react-native";

type Props = ViewProps;

export const Page = (props: Props) => {
  return <ScrollView {...props} style={[S.page, props.style]} />;
};

const S = StyleSheet.create({
  page: {
    padding: 40,
    flex: 1,
  },
});
