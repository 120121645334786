import { ApplicationRepo, CompanyRepo, GigRepo } from "@opencraft/common";
import { RouteProp, useRoute } from "@react-navigation/native";
import React from "react";
import { FlatList, ScrollView, StyleSheet } from "react-native";
import { AppNavParams, useNav } from "../app/navigation";
import { Application } from "../components/Application";
import { Button } from "../components/Button";
import { Card } from "../components/Card";
import { JobRow } from "../components/JobRow";
import { Loader, loading } from "../components/Loader";
import { Title } from "../components/StyledText";
import { useCurrentUser, useRequiredCurrentUser } from "../util/auth";
import { useCompany } from "../util/hooks";
import { useRepo } from "../util/store";
import { useTheme } from "../util/theme";

const GigDetail = () => {
  const user = useCurrentUser();
  const theme = useTheme();
  const nav = useNav("App");

  const route = useRoute<RouteProp<AppNavParams, "GigDetail">>();

  const companyRepo = useRepo(CompanyRepo);
  const { useGet } = useRepo(GigRepo);
  const { useQuery } = useRepo(ApplicationRepo);
  const [loadingGig, gig] = useGet(route.params.id);

  const [loadingCompany, company] = useCompany();

  const [loadingApplications, apps] = useQuery(
    {
      where: [
        {
          field: "gigId",
          op: "==",
          value: gig && gig.id,
        },
      ],
    },
    [gig]
  );

  if (!gig || loading(loadingGig)) {
    return <Loader visible />;
  }

  const isOwner = company && gig && company.id === gig.owner;

  return (
    <ScrollView style={S.page}>
      <JobRow isOwner={isOwner} gig={gig} />
      {isOwner && (
        <Button
          style={{ alignSelf: "flex-end" }}
          onPress={() => {
            nav.navigate("App", {
              screen: "EditGig",
              params: {
                companyId: company.id,
                id: gig.id,
              },
            });
          }}
          label="Edit Gig"
        ></Button>
      )}

      {isOwner && (
        <Card>
          <Title style={{ marginBottom: theme.spacing.gutter }}>
            Applicants
          </Title>
          <FlatList
            renderItem={({ item }) => {
              console.log(item);
              return <Application applicantId={item.userId} />;
            }}
            data={apps}
            ListEmptyComponent={() => <Title>No Applications So Far</Title>}
          />
        </Card>
      )}
    </ScrollView>
  );
};

export { GigDetail };

const S = StyleSheet.create({
  page: {
    padding: 40,
    flex: 1,
  },

  profile: {
    justifyContent: "center",
    alignItems: "center",
  },
});
