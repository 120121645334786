import React from "react";
import { Image, Pressable } from "react-native";
import { HasPic, useImage } from "../util/hooks";
import { useTheme } from "../util/theme";
import { Loader } from "./Loader";

type Props = {
  size?: number;
  profile?: HasPic;
  override?: string;
  onPress?: () => void;
};

export const Avatar = ({ profile, onPress, override, size = 100 }: Props) => {
  const [loadingPhoto, uploadedPhoto] = useImage(profile);

  const uri =
    override ||
    uploadedPhoto ||
    `https://avatars.dicebear.com/api/identicon/${profile.id}.svg`;

  const theme = useTheme();

  return (
    <Pressable
      onPress={() => {
        onPress && onPress();
      }}
      style={{
        height: size,
        width: size,
        borderRadius: size / 2,
        backgroundColor: "white",
        overflow: "hidden",
      }}
    >
      {loadingPhoto ? (
        <Loader visible />
      ) : (
        <Image
          style={{
            height: size,
            width: size,
            borderRadius: size / 2,
            borderWidth: 3,
            borderColor: theme.colors.disabled,
          }}
          source={{
            uri,
          }}
        />
      )}
    </Pressable>
  );
};
