import { Ionicons } from "@expo/vector-icons";
import React from "react";
import {
  Platform,
  TextInput as RNTextInput,
  TextInputProps,
  View,
} from "react-native";
import { useTheme } from "../util/theme";

type Props = TextInputProps & {
  icon?: "location" | "time";
  type?: "date";
};

export const TextInput = (props: Props) => {
  const theme = useTheme();

  return (
    <View style={{ flexBasis: "auto" }}>
      {props.icon && (
        <Ionicons
          style={{ position: "absolute", right: 10, top: 8 }}
          name={props.icon}
          size={15}
        />
      )}
      {props.type === "date" && Platform.OS === "web" ? (
        <input
          type="date"
          placeholder={props.placeholder}
          style={{
            outlineColor: theme.colors.primary,
            fontFamily: theme.fonts.body,
            border: "none",
            padding: 10,

            borderRadius: theme.roundness,
            backgroundColor: theme.colors.background,
            ...props.style,
          }}
          onChange={(e) => {
            props.onChangeText(e.target.value);
          }}
        />
      ) : (
        <RNTextInput
          {...props}
          style={[
            {
              // @ts-ignore
              outlineColor: theme.colors.primary,
              fontFamily: theme.fonts.body,
              padding: 10,
              borderRadius: theme.roundness,
              backgroundColor: theme.colors.background,
              marginBottom: theme.spacing.gutter,
              paddingRight: props.icon ? 40 : 10,
              color: theme.colors.text,
            },
            props.style,
          ]}
        ></RNTextInput>
      )}
    </View>
  );
};
