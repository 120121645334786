import timezones from "timezones-list";

export type TZOptionShape = {
  label: string;
  tzCode: string;
  name: string;
  utc: string;
  value: string;
};

export const TZOptions: TZOptionShape[] = timezones.map((i) => {
  return {
    ...i,
    label: `${i.name} ${i.label}`,
    value: i.tzCode,
  };
});

export const TZOptionsMap: Record<string, TZOptionShape> = TZOptions.reduce(
  (prev, item) => {
    prev[item.tzCode] = item;
    return prev;
  },
  {}
);
