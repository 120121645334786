import Toast from "react-native-root-toast";
import { useTheme } from "./theme";

export const useNotify = () => {
  const theme = useTheme();

  return (
    message: string,
    level: "INFO" | "WARN" | "ERROR" = "INFO",
    duration: "SHORT" | "LONG" = "SHORT"
  ) => {
    Toast.show(message, {
      duration: Toast.durations[duration],
      position: Toast.positions.TOP,
      backgroundColor: theme.colors[level.toLowerCase()],
      animation: false,
      opacity: 1,
    });
  };
};
