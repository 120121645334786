import { FontAwesome } from "@expo/vector-icons";
import { CompanyRepo, Gig, GigRepo, GigStatus } from "@opencraft/common";
import { RouteProp, useRoute } from "@react-navigation/native";
import { format, parse } from "date-fns";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Pressable, StyleSheet, Switch, Text, View } from "react-native";
import { useHover } from "react-native-web-hooks";
import { AppNavParams, useNav } from "../app/navigation";
import { Button } from "../components/Button";
import { Card } from "../components/Card";
import { Loader } from "../components/Loader";
import { Page } from "../components/Page";
import { TimezoneSelect } from "../components/Selects";
import { Label, Title } from "../components/StyledText";
import { TextInput } from "../components/TextInput";
import { TokenMash } from "../components/TokenMash";
import { Timestamp, useRepo } from "../util/store";
import { Roundness, Spacing, useTheme } from "../util/theme";
import { useNotify } from "../util/toast";

export const CreateGig = () => {
  // const user = useRequiredCurrentUser();
  const theme = useTheme();

  const route = useRoute<RouteProp<AppNavParams, "CreateGig">>();

  const nav = useNav("App");

  // used in edit gig route
  const { companyId, id } = route.params;

  const [gig, setGig] = useState<Partial<Gig>>({});
  const [loadingExistingGig, setLoadingExistingGig] = useState(false);

  const updateGig = useCallback(
    (update: Partial<Gig>) => {
      setGig({ ...gig, ...update });
    },
    [gig]
  );

  useEffect(() => {
    if (id) {
      const getGig = async () => {
        setLoadingExistingGig(true);
        const existingGig = await GigRepo.get(id);
        setGig(existingGig);
        setLoadingExistingGig(false);
      };

      getGig();
    }
  }, [id]);

  const { useGet } = useRepo(CompanyRepo);
  const { create, update } = useRepo(GigRepo);
  const [creating, setCreating] = useState(false);
  const [loading, company] = useGet(companyId);
  const notify = useNotify();

  const valid = () => {
    if (!gig.title || gig.title.length < 3) {
      return false;
    }

    if (!gig.description || gig.description.length < 3) {
      return false;
    }

    return true;
  };

  if (loadingExistingGig) {
    return <Loader visible />;
  }

  return (
    <Page>
      <Card>
        <Title>Create a New Gig</Title>
        <TextInput
          placeholder="title"
          value={gig.title || ""}
          onChangeText={(val) => {
            updateGig({ title: val });
          }}
          style={{ marginTop: theme.spacing.gutter }}
        />
        <TextInput
          value={gig.description || ""}
          onChangeText={(val) => {
            updateGig({ description: val });
          }}
          multiline
          numberOfLines={5}
          placeholder="description"
        />
        <View style={{ marginBottom: 20 }}>
          <Label>Accepted Currencies</Label>
          {company && <TokenMash tokens={company.currencies} />}
        </View>
        <Label>Choose Timezone</Label>
        <TimezoneSelect
          value={gig.timezone}
          onChange={(v) => {
            updateGig({ timezone: v });
          }}
        />
        <View
          style={{
            flexDirection: "row",
            marginVertical: 15,
            marginTop: 20,
            alignItems: "center",
          }}
        >
          <Switch
            onValueChange={(val) => {
              updateGig({ anonOk: val });
            }}
            value={gig.anonOk}
          />
          <Label style={{ marginLeft: 5 }}>Anon OK?</Label>
        </View>
        <Label>Choose Location</Label>
        <TextInput
          placeholder="e.g., New York"
          defaultValue={gig.location}
          style={{}}
          onChangeText={(val) => {
            updateGig({ location: val });
          }}
        />
        <Label>Choose Start Date</Label>
        <TextInput
          placeholder={`e.g., ${format(new Date(), "LL/dd/yyyy")}`}
          type="date"
          value={
            gig.startDate ? format(gig.startDate.toDate(), "LL/dd/yyyy") : ""
          }
          style={{
            color: "white",
            // @ts-ignore
            outline: "none",
          }}
          onChangeText={(val) => {
            updateGig({
              startDate: Timestamp.fromMillis(
                parse(val, "yyyy-LL-dd", new Date()).getTime()
              ),
            });
          }}
        />
        <View style={{ height: theme.spacing.margin }}></View>

        <Button
          loading={creating}
          disabled={!valid()}
          onPress={async () => {
            if (valid()) {
              setCreating(true);

              try {
                if (gig.id) {
                  const result = await update(gig.id, gig);
                  nav.navigate("App", {
                    screen: "GigDetail",
                    params: {
                      id: gig.id,
                      companyId: gig.owner,
                    },
                  });
                } else {
                  const result = await create({
                    ...gig,
                    currencies: company.currencies,
                    owner: company.id,
                    status: GigStatus.OPEN,
                  });
                  notify("Created a new gig!");
                  nav.navigate("App", {
                    screen: "GigDetail",
                    params: {
                      id: result.id,
                      companyId: result.owner,
                    },
                  });
                }
              } catch (e) {
                notify("Failed to create a new gig!", "ERROR");
              }

              setCreating(false);
            }
          }}
          label={gig.id ? "Update" : "Create"}
          style={{ alignSelf: "flex-end" }}
        ></Button>
      </Card>
    </Page>
  );
};

const S = StyleSheet.create({
  iconBar: {
    flexDirection: "row",
    marginTop: Spacing.spacing.margin,
    borderRadius: Roundness.roundness,
    justifyContent: "space-between",
    alignItems: "center",
    borderColor: "#ccc",
    padding: Spacing.spacing.padding,
    borderWidth: 1,
    shadowColor: "rgba(0, 0, 0, .5)",
    shadowRadius: 2,
    shadowOffset: {
      height: 0,
      width: 0,
    },
    marginBottom: Spacing.spacing.gutter,
  },
});

type FilterIndicatorRowProps = {
  onClear: () => void;
  content: string;
};

const FilterIndicatorRow = ({ onClear, content }: FilterIndicatorRowProps) => {
  const ref = useRef();
  const hovering = useHover(ref);
  const theme = useTheme();

  return (
    <View style={FilterIndicatorRowS.container}>
      <Text>— {content}</Text>
      <Pressable ref={ref} style={{ marginLeft: 5 }} onPress={onClear}>
        <FontAwesome
          name="close"
          color={hovering ? theme.colors.error : theme.colors.disabled}
        />
      </Pressable>
    </View>
  );
};

const FilterIndicatorRowS = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
});
