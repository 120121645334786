import { Ionicons } from "@expo/vector-icons";
import React, { ReactNode, useMemo, useState } from "react";
import {
  Modal,
  Pressable,
  StyleSheet,
  Text,
  View,
  ScrollView,
} from "react-native";
import { TextInput } from "../components/TextInput";
import { useBreakpoints } from "../util/hooks";
import { Roundness, useTheme } from "../util/theme";
import { Button } from "./Button";
import { Dialog } from "./Dialog";
type Props<T> = {
  searchTerm: string;
  filters: Partial<T>;
  onFilterUpdate: (searchTerm: string, filters: Partial<T>) => void;
  children: ReactNode;
  scrollable?: boolean;
};

export function SearchAndFilter<T>({
  searchTerm,
  filters,
  onFilterUpdate,
  children,
  scrollable,
}: Props<T>) {
  const theme = useTheme();
  const [filtersVisible, setFiltersVisible] = useState(false);

  const [hasFilters, filterCount] = useMemo(() => {
    const count = Object.values(filters).filter((f) => f).length;
    return [count > 0, count];
  }, [filters]);

  const [mobile, tablet] = useBreakpoints();

  return (
    <View style={S.searchContent}>
      <TextInput
        placeholder="search... "
        value={searchTerm}
        style={{ backgroundColor: theme.colors.ambientBG }}
        onChangeText={(val) => {
          onFilterUpdate(val, filters);
        }}
      />

      <Pressable
        style={{
          padding: 8,
          alignSelf: "flex-end",
          marginTop: 0,
          borderRadius: 100,
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: hasFilters ? theme.colors.accent : "transparent",
        }}
        onPress={() => {
          setFiltersVisible(true);
        }}
      >
        {hasFilters && (
          <Text
            style={{
              color: "white",
              marginRight: 5,

              fontWeight: "bold",
            }}
          >
            {filterCount}
          </Text>
        )}
        <Ionicons
          name="options-outline"
          size={24}
          color={hasFilters ? "white" : theme.colors.text}
        ></Ionicons>
      </Pressable>
      <Dialog
        scrollable={scrollable}
        visible={filtersVisible}
        title="Filters"
        onDismiss={() => setFiltersVisible(false)}
        Footer={
          <View
            style={{
              alignSelf: "flex-end",
              flexDirection: "row",
              marginTop: theme.spacing.gutter,
            }}
          >
            <Button
              style={{ marginRight: 10 }}
              format="outline"
              label="Clear Filters"
              color={theme.colors.accent}
              onPress={() => {
                onFilterUpdate("", {});
              }}
            />
            <Button
              label="Done"
              onPress={() => {
                setFiltersVisible(false);
              }}
            />
          </View>
        }
      >
        <View style={{ flex: 1 }}>{children}</View>
      </Dialog>
    </View>
  );
}

const S = StyleSheet.create({
  page: {
    padding: 40,
    flex: 1,
  },

  searchContent: {
    flex: 1,
    marginBottom: 40,
  },

  card: {
    backgroundColor: "white",
    maxWidth: 1100,
    padding: 20,
    borderRadius: Roundness.roundness,
    shadowColor: "rgba(0, 0, 0, .5)",
    shadowRadius: 2,
    shadowOffset: {
      width: 1,
      height: 1,
    },
    marginBottom: 20,
  },
});
