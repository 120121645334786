import React from "react";
import { RootNavParams, useNav } from "../app/navigation";

import { useTheme } from "../util/theme";

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { OnboardingView } from "../components/OnboardingView";

import { View } from "react-native";
import { SignInLockup } from "../components/SignInLockup";
import { useNotify } from "../util/toast";

type Props = NativeStackScreenProps<RootNavParams, "OnboardingProjectSignIn">;

export const OnboardingProjectSignIn = (props: Props) => {
  const { step } = props.route.params;
  const nav = useNav("OnboardingProjectSignIn");
  const theme = useTheme();

  const notify = useNotify();

  return (
    <OnboardingView
      noAction
      step={parseInt(step)}
      title="Sign In"
      description="Before creating an organization, first sign in. We recommend MetaMask, but email works too."
    >
      <View
        style={{
          maxWidth: 400,
          marginTop: theme.spacing.margin,
          padding: theme.spacing.padding,
          flex: 1,
          backgroundColor: theme.colors.ambientBG,
        }}
      >
        <SignInLockup
          methods={["Email", "Metamask"]}
          onAuth={(res) => {
            if (res.success === false) {
              notify(res.error.message, "ERROR", "LONG");
            } else {
              console.log("authed", res);
              nav.navigate("OnboardingProjectStart", {
                step: `${parseInt(step) + 1}`,
              });
            }
          }}
        />
      </View>
    </OnboardingView>
  );
};
