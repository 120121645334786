import { Ionicons } from "@expo/vector-icons";
import {
  CompanyRepo,
  Experience,
  GigRepo,
  Profile,
  ProfileRepo,
  RemotePreference,
  Web3Experience,
  WorkStyle,
} from "@opencraft/common";
import React, { useMemo, useState } from "react";
import { FlatList, ScrollView, View } from "react-native";
import { useNav } from "../app/navigation";
import { Button } from "../components/Button";
import { ButtonSelect } from "../components/ButtonSelect";
import { Card } from "../components/Card";
import { JobRow } from "../components/JobRow";
import { Loader } from "../components/Loader";
import { ProfilePreview } from "../components/ProfilePreview";
import { SearchAndFilter } from "../components/SearchAndFilter";
import { CurrencySelect, TimezoneSelect } from "../components/Selects";
import { Label, Title, AppText as Text } from "../components/StyledText";
import { useCurrentUser } from "../util/auth";
import { useBreakpoints } from "../util/hooks";
import { useRepo } from "../util/store";
import { useTheme } from "../util/theme";
import {
  ExperienceOptions,
  RemotePreferenceOptions,
  Web3ExperienceOptions,
  WorkStyleOptions,
} from "./ProfileDetail";

export const ProfileSearch = () => {
  const user = useCurrentUser();
  const theme = useTheme();

  const repo = useRepo(GigRepo);
  const companyRepo = useRepo(CompanyRepo);
  const profileRepo = useRepo(ProfileRepo);

  const nav = useNav("Home");

  const [loading, company] = companyRepo.useQuery(
    {
      where: [
        {
          field: "owner",
          op: "==",
          value: user?.id,
        },
      ],
    },
    [user?.id]
  );

  const [loadingGigs, gigs] = repo.useQuery(
    {
      where: [
        {
          field: "owner",
          op: "==",
          value: company.length > 0 ? company[0].id : null,
        },
      ],
    },
    [company.length > 0] // don't load this unless we have aa company
  );

  let [loadingProfiles, profiles] = profileRepo.useQuery({
    where: [
      {
        field: "published",
        op: "==",
        value: true,
      },
    ],
  });

  const [searchTerm, setSearchTerm] = useState("");

  const [filters, setFilters] = useState<Partial<Profile>>({});

  const [moble, tablet] = useBreakpoints();

  const filteredProfiles = useMemo(() => {
    if (searchTerm.length < 1 && Object.keys(filters).length == 0) {
      return profiles;
    }

    let regex = new RegExp(searchTerm.split("").join(".*"));

    return profiles.filter((p) => {
      // consider https://fusejs.io/examples.html#search-string-array
      let matches = true;

      if (searchTerm.length > 0) {
        if (!regex.exec(p.bio + " " + p.role)) {
          matches = false;
        }
      }

      if ("timezone" in filters) {
        if (filters.timezone != p.timezone) {
          matches = false;
        }
      }

      if ("experience" in filters) {
        if (filters.experience != p.experience) {
          matches = false;
        }
      }

      if ("web3Exp" in filters) {
        if (filters.web3Exp != p.web3Exp) {
          matches = false;
        }
      }

      if ("remote" in filters) {
        if (filters.remote != p.remote) {
          matches = false;
        }
      }

      if ("style" in filters) {
        if (filters.style != p.style) {
          matches = false;
        }
      }

      if (
        "preferredCurrencies" in filters &&
        filters.preferredCurrencies.length > 0
      ) {
        let accepts = new Set(filters.preferredCurrencies);

        if (
          !p.preferredCurrencies ||
          p.preferredCurrencies.filter((c) => accepts.has(c)).length === 0
        ) {
          matches = false;
        }
      }

      return matches;
    });
  }, [searchTerm, profiles, filters]);

  return (
    <ScrollView style={{ padding: theme.spacing.margin }}>
      <View style={{ maxWidth: 1100 }}>
        <Loader visible={loading} />
        {company.length > 0 && (
          <>
            {gigs.length > 0 && <Title>Your Open Gigs</Title>}
            <FlatList
              data={gigs}
              renderItem={({ item }) => {
                return <JobRow gig={item} isOwner />;
              }}
              ListEmptyComponent={() => {
                return (
                  <Card
                    style={{
                      flex: 1,
                    }}
                  >
                    <Title>You don't have any open gigs</Title>
                    <Button
                      style={{ alignSelf: "flex-start", marginTop: 20 }}
                      label="Create New Gig"
                      onPress={() => {
                        nav.navigate("App", {
                          screen: "CreateGig",
                          params: {
                            companyId: company[0].id,
                          },
                        });
                      }}
                    />
                  </Card>
                );
              }}
            />
          </>
        )}
        {!loading && company.length < 1 && user && (
          <Card>
            <Title>Register Organization</Title>
            <Text style={{ marginVertical: 10 }}>
              To start recruiting, first register an orgnization. It only takes
              about 5 minutes.
            </Text>
            <Button
              style={{ alignSelf: "flex-start" }}
              label="Start"
              onPress={() => {
                nav.navigate("OnboardingProjectStart", {
                  step: "1",
                });
              }}
            ></Button>
          </Card>
        )}
      </View>
      <View style={{ maxWidth: 1100 }}>
        <Title style={{ marginBottom: 20 }}>Search Profiles</Title>
        <SearchAndFilter
          searchTerm={searchTerm}
          filters={filters}
          scrollable={true}
          onFilterUpdate={(searchTerm, filters) => {
            setSearchTerm(searchTerm);
            setFilters(filters);
          }}
        >
          <>
            <Label>Timezone</Label>
            <TimezoneSelect
              value={filters.timezone}
              onChange={(val) => {
                setFilters({ ...filters, timezone: val });
              }}
            />
            <View style={{ height: 20 }}></View>
            <Label>Preferred Currencies</Label>
            <CurrencySelect
              value={filters.preferredCurrencies || []}
              onChange={(val) => {
                setFilters({ ...filters, preferredCurrencies: val });
              }}
            />
            <View style={{ height: 20 }}></View>
            <ButtonSelect
              options={ExperienceOptions}
              value={filters.experience}
              label="General Experience"
              onChange={(val) => {
                setFilters({ ...filters, experience: val as Experience });
              }}
            />
            <ButtonSelect
              options={WorkStyleOptions}
              value={filters.style}
              label="Work style"
              onChange={(val) => {
                setFilters({ ...filters, style: val as WorkStyle });
              }}
            />
            <ButtonSelect
              options={Web3ExperienceOptions}
              value={filters.web3Exp}
              label="Web3 Experience"
              onChange={(val) => {
                setFilters({ ...filters, web3Exp: val as Web3Experience });
              }}
            />
            <ButtonSelect
              options={RemotePreferenceOptions}
              value={filters.remote}
              label="Remote Preference"
              onChange={(val) => {
                setFilters({ ...filters, remote: val as RemotePreference });
              }}
            />
          </>
        </SearchAndFilter>
        <FlatList
          data={filteredProfiles}
          contentContainerStyle={{ marginTop: theme.spacing.padding }}
          numColumns={tablet ? 3 : 1}
          renderItem={({ item }) => {
            return (
              <ProfilePreview
                profile={item}
                company={company.length > 0 ? company[0] : undefined}
              />
            );
          }}
          ListEmptyComponent={() => {
            return (
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Ionicons name="search" size={80} color={theme.colors.text} />
                <Title style={{ marginBottom: 20 }}>
                  No profiles match your search or filters
                </Title>
                <Button
                  onPress={() => {
                    nav.navigate("OnboardingProjectStart", {
                      step: "1",
                    });
                  }}
                  label="Create New Gig"
                />
              </View>
            );
          }}
        />
      </View>
    </ScrollView>
  );
};
