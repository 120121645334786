import React, { useState } from "react";
import { RootNavParams, useNav } from "../app/navigation";

import { useTheme } from "../util/theme";

import { Company, CompanyRepo } from "@opencraft/common";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import CryptoIconManifest from "cryptocurrency-icons/manifest.json";
import { OnboardingView } from "../components/OnboardingView";
import { TextInput } from "../components/TextInput";
import { Preload } from "../util/Preloadable";
import { useRepo } from "../util/store";

type OptionShape = {
  symbol: string;
  name: string;
  color: string;
  label: string;
  value: string;
};

const options: OptionShape[] = CryptoIconManifest.map((i) => {
  return {
    ...i,
    label: `${i.name} ${i.symbol}`,
    value: i.symbol,
  };
});

const optionMap: Record<string, OptionShape> = options.reduce((prev, item) => {
  prev[item.symbol] = item;
  return prev;
}, {});

type Props = NativeStackScreenProps<RootNavParams, "OnboardingProjectLinks">;

type PreloadedProps = {
  company?: Company;
};

export const OnboardingProjectLinks = Preload<Props, PreloadedProps>(
  (props) => {
    const { step } = props.route.params;
    const nav = useNav("OnboardingProjectLinks");
    const theme = useTheme();

    const company = props.preloaded.company;

    const [website, setWebsite] = useState(company?.website);
    const [twitter, setTwitter] = useState(company?.twitter);
    const [discord, setDiscord] = useState(company?.discord);

    const { update } = useRepo(CompanyRepo);

    return (
      <OnboardingView
        step={parseInt(step)}
        title="Where can we find you?"
        description="Your community and social properties are the easiest way for freelancers to vet and familiarize themselves with your project."
        onNext={() => {
          if (company) {
            update(company.id, {
              twitter,
              website,
              discord,
            });
          }

          nav.navigate("App", {
            screen: "CompanyDetail",
            params: {
              id: company.id,
            },
          });
        }}
      >
        <TextInput
          value={website}
          style={{
            marginVertical: 20,
            backgroundColor: theme.colors.ambientBG,
          }}
          placeholder="website"
          onChangeText={setWebsite}
        />
        <TextInput
          value={twitter}
          style={{ marginBottom: 20, backgroundColor: theme.colors.ambientBG }}
          placeholder="twitter"
          onChangeText={setTwitter}
        />
        <TextInput
          value={discord}
          style={{ backgroundColor: theme.colors.ambientBG }}
          placeholder="discord"
          onChangeText={setDiscord}
        />
      </OnboardingView>
    );
  },
  async (props, context) => {
    const co = await CompanyRepo.query({
      where: [
        {
          field: "owner",
          op: "==",
          value: context.currentUser?.id,
        },
      ],
    });

    return {
      company: co.length > 0 ? co[0] : undefined,
    };
  }
);
