import React from "react";
import { StyleProp, TextInput } from "react-native";
import { CryptoOptions, CryptoOptionsMap } from "../util/crypto";
import { TZOptions, TZOptionsMap } from "../util/tz";
import { MultiSelect } from "./MultiSelect.web";

type Props = {
  value: string;
  onChange: (val: string) => void;
};

export const TimezoneSelect = ({ value, onChange }: Props) => {
  return (
    <MultiSelect
      placeholder="e.g., EST"
      multi={false}
      onChange={(res) => {
        onChange(res.tzCode);
      }}
      value={TZOptionsMap[value]}
      options={TZOptions}
    />
  );
};

type CurrencyProps = {
  value: string[];
  disabled?: boolean;
  onChange: (val: string[]) => void;
  style?: StyleProp<TextInput>;
};

export const CurrencySelect = ({
  value,
  onChange,
  disabled,
  style,
}: CurrencyProps) => {
  return (
    <MultiSelect
      multi
      style={style}
      disabled={disabled}
      value={value.map((v) => CryptoOptionsMap[v])}
      options={CryptoOptions}
      placeholder="Select preferred currencies"
      onChange={(result) => {
        if (Array.isArray(result)) {
          onChange(result.map((r) => r.symbol));
        }
      }}
    />
  );
};
