import React, { ReactNode } from "react";
import { StyleSheet, View } from "react-native";

import { Roundness, useTheme } from "../util/theme";

import { ViewProps } from "react-native";

type Props = ViewProps & {
  Header?: ReactNode;
};

export const Card = (props: Props) => {
  const theme = useTheme();

  const { Header } = props;

  return (
    <View
      {...props}
      style={[
        S.card,
        props.style,
        {
          borderColor: theme.colors.border,
          borderWidth: 1,
          padding: props.Header ? 0 : 20,
          backgroundColor: theme.colors.ambientBG,
        },
      ]}
    >
      {Header && <Header />}
      <View style={{ padding: props.Header ? 20 : 0, flex: 1 }}>
        {props.children}
      </View>
    </View>
  );
};

const S = StyleSheet.create({
  card: {
    maxWidth: 1100,

    padding: 20,
    borderRadius: Roundness.roundness,

    marginBottom: 20,
  },
});
