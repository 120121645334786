import React from "react";
import { Dialog, DialogProps } from "./Dialog";
import { SignInLockup } from "./SignInLockup";

type Props = Pick<DialogProps, "visible" | "onDismiss">;

export const SignInDialog = (props: Props) => {
  return (
    <Dialog title="" {...props}>
      <SignInLockup methods={["Metamask", "Email"]} onAuth={() => {}} />
    </Dialog>
  );
};
