import { FontAwesome5 } from "@expo/vector-icons";
import React, { useState } from "react";
import { Pressable, View, ViewProps } from "react-native";
import { Popover } from "react-native-popable";
import { useTheme } from "../util/theme";

type Props = {
  content: string;
  name: string;
  size: number;
  contentPosition?: "top" | "left" | "right" | "bottom";
  color?: string;
  hoverColor?: string;
  onPress?: () => void;
} & ViewProps;

export const IconButton = (props: Props) => {
  const {
    name,
    size,
    color,
    content,
    hoverColor,
    onPress,
    contentPosition = "top",
  } = props;
  const theme = useTheme();

  const [hovering, setHovering] = useState(false);
  const [height, setHeight] = useState(0);

  return (
    <Pressable
      {...props}
      // @ts-ignore
      onHoverIn={() => {
        setHovering(true);
      }}
      onHoverOut={() => {
        setHovering(false);
      }}
      style={[
        {
          alignSelf: "flex-start",
          justifyContent: "center",
          alignItems: "center",
        },
        props.style,
      ]}
      onPress={() => {
        console.log("pressed");
        onPress && onPress();
      }}
    >
      <FontAwesome5
        color={hovering ? hoverColor : color}
        name={name}
        size={size}
      />
      <View
        style={{
          position: "absolute",
          top: contentPosition === "top" ? 0 - height : height,
        }}
      >
        <Popover
          position={contentPosition}
          visible={hovering}
          onLayout={(res) => {
            setHeight(res.nativeEvent.layout.height);
          }}
        >
          {content}
        </Popover>
      </View>
    </Pressable>
  );
};
