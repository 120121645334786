import { ProfileRepo } from "@opencraft/common";
import { BottomTabBarProps } from "@react-navigation/bottom-tabs";
import React, { useEffect, useRef, useState } from "react";
import { Pressable, View, ViewProps } from "react-native";
import { useHover } from "react-native-web-hooks";
import { useNav } from "../app/navigation";
import { useCurrentUser, useLogout, useTriggerLogin } from "../util/auth";
import { useBreakpoints, useCompany, useUnreadMessages } from "../util/hooks";
import { useRepo } from "../util/store";
import { useTheme } from "../util/theme";
import { Avatar } from "./Avatar";
import { IconButton } from "./IconButton";
import { MenuLink } from "./MenuLink";
import { NavLink } from "./NavLink";
import { AppText as Text, Title } from "./StyledText";

type Props = BottomTabBarProps;

export const NavBar = (props: Props) => {
  const theme = useTheme();

  const { state } = props;

  const triggerLogin = useTriggerLogin();

  const currentUser = useCurrentUser();
  const logout = useLogout();
  const profileRepo = useRepo(ProfileRepo);
  const [loading, profile] = profileRepo.useGet(currentUser?.id);
  const nav = useNav("App");

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const [mobile, tablet] = useBreakpoints();

  const ref = useRef(null);
  const menuRef = useRef(null);

  const [hovering, setHovering] = useState(false);

  const unreadMessages = useUnreadMessages();

  const [loadingCompany, company] = useCompany();

  // extra hover logic to hold the menu open when you
  // hover over it
  const hover = useHover(ref);
  const hoverMenu = useHover(menuRef);
  useEffect(() => {
    if (hover || hoverMenu) {
      setHovering(true);
    } else {
      setHovering(false);
    }
  }, [hover, hoverMenu]);

  const route = state.routes[state.index];

  return (
    <Bar>
      <Pressable
        onPress={() => {
          props.navigation.navigate("JobSearch");
        }}
      >
        <Title>
          Open<Text style={{ color: theme.colors.primary }}>/</Text>
          Craft
        </Title>
      </Pressable>
      <View
        style={{
          flexDirection: "row",

          alignItems: "center",
        }}
      >
        <NavLink
          label="Jobs"
          active={route.name === "JobSearch"}
          onPress={() => {
            props.navigation.navigate("JobSearch");
          }}
        />
        <NavLink
          label="Talent"
          active={route.name === "ProfileSearch"}
          onPress={() => {
            props.navigation.navigate("ProfileSearch");
          }}
        />

        <View>
          <IconButton
            name="comment"
            style={{
              alignSelf: "center",
              marginLeft: theme.spacing.gutter,
            }}
            content="messages"
            contentPosition="bottom"
            size={35}
            color={theme.colors.text}
            hoverColor={theme.colors.accent}
            onPress={() => {
              if (!currentUser) {
                // route to signin
                triggerLogin();
              } else {
                props.navigation.navigate("Messages");
              }
            }}
          />
          {unreadMessages.length > 0 && (
            <View
              style={{
                backgroundColor: theme.colors.error,
                height: 20,
                width: 20,
                position: "absolute",
                bottom: 0,
                right: 0,
                borderRadius: 10,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ color: "white", fontWeight: "bold" }}>
                {unreadMessages.length}
              </Text>
            </View>
          )}
        </View>
        {currentUser ? (
          <View ref={ref} style={{ marginLeft: 50 }}>
            {profile && (
              <Avatar
                onPress={() => {
                  console.log("setting visble");
                  setDropdownVisible(true);
                }}
                profile={profile}
                size={50}
              />
            )}
            <View
              ref={menuRef}
              style={{
                position: "absolute",
                right: 0,
                top: 40,
                display: hovering ? "flex" : "none",
              }}
            >
              <View
                style={{
                  marginTop: 20,
                  width: 200,
                  borderRadius: theme.roundness,
                  backgroundColor: theme.colors.background,
                  shadowColor: "rgba(0, 0, 0, .5)",
                  flex: 1,
                  shadowOffset: {
                    height: 1,
                    width: 1,
                  },
                  paddingVertical: 2,

                  shadowRadius: 2,
                }}
              >
                <MenuLink
                  onPress={() => {
                    nav.navigate("App", {
                      screen: "ProfileDetail",
                      params: {
                        id: currentUser.id,
                      },
                    });
                  }}
                  title="View Profile"
                  icon="person-circle-outline"
                />
                {company && (
                  <MenuLink
                    onPress={() => {
                      nav.navigate("App", {
                        screen: "CompanyDetail",
                        params: {
                          id: company.id,
                        },
                      });
                    }}
                    title="My Organization"
                    icon="business-outline"
                  />
                )}
                <MenuLink
                  onPress={() => {
                    logout();
                  }}
                  title="Logout"
                  icon="log-out-outline"
                />
              </View>
            </View>
          </View>
        ) : null}
      </View>
    </Bar>
  );
};

const Bar = (props: ViewProps) => {
  const theme = useTheme();

  return (
    <View
      style={{
        position: "absolute",
        width: "100%",
        height: 80,

        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        shadowColor: "black",
        shadowRadius: 2,
        shadowOffset: {
          height: 0,
          width: 0,
        },
        paddingHorizontal: theme.spacing.margin,
        // borderBottomWidth: 1,
        // borderBottomColor: theme.colors.disabled,
        backgroundColor: theme.colors.ambientBG,
      }}
    >
      {props.children}
    </View>
  );
};
