import React from "react";
import Select, { components, MultiValueProps, OptionProps } from "react-select";
import { useTheme } from "../util/theme";

import { Image, StyleProp, TextInput, View } from "react-native";
import { AppText as Text } from "./StyledText";

type BasicOption = {
  name: string;
  label: string;
  value: string;
  uri?: string;
};

const CustomOption = <T extends BasicOption>(props: OptionProps<T>) => {
  return (
    <components.Option {...props}>
      <View style={{ flexDirection: "row", paddingVertical: 5 }}>
        {/* <CryptoIcon name="ada" /> */}
        {props.data.uri && (
          <Image
            style={{ height: 20, width: 20, marginRight: 5 }}
            source={{
              uri: props.data.uri,
            }}
          />
        )}
        <Text>
          {props.data.name}{" "}
          <Text style={{ fontWeight: "bold" }}>({props.data.value})</Text>
        </Text>
      </View>
    </components.Option>
  );
};

const CustomMultiValue = <T extends BasicOption>(props: MultiValueProps<T>) => {
  return (
    <components.MultiValueContainer {...props}>
      <View
        style={{ flexDirection: "row", alignItems: "center", marginRight: 10 }}
      >
        {/* <CryptoIcon name="ada" /> */}

        <Text style={{ fontWeight: "bold" }}>{props.data.value}</Text>

        {props.data.uri && (
          <Image
            style={{ height: 20, width: 20, marginLeft: 5 }}
            source={{
              uri: props.data.uri,
            }}
          />
        )}
      </View>
    </components.MultiValueContainer>
  );
};

type MultiProps<T extends BasicOption> = {
  disabled?: boolean;
  options: T[];
  onChange: (res: T[]) => void;
  value: T[];
  placeholder: string;
  multi: true;
  style?: StyleProp<TextInput>;
};

type SingleProps<T extends BasicOption> = {
  disabled?: boolean;
  options: T[];
  onChange: (res: T) => void;
  value: T;
  placeholder: string;
  multi: false;
  style?: StyleProp<TextInput>;
};

type Props<T extends BasicOption> = SingleProps<T> | MultiProps<T>;

export const MultiSelect = <T extends BasicOption>({
  disabled,
  options,
  placeholder,
  multi,
  onChange,
  style,
  value,
}: Props<T>) => {
  const theme = useTheme();

  const customStyles = style ?? {};

  return (
    <Select
      isDisabled={disabled}
      isMulti={multi}
      value={value}
      placeholder={placeholder}
      menuPortalTarget={document.body}
      onChange={(result) => {
        if (multi && Array.isArray(result)) {
          onChange(result);
        } else if (!multi && !Array.isArray(result)) {
          onChange(result);
        } else {
          console.error("this multiselect has been misconfigured");
        }
      }}
      components={{ Option: CustomOption, MultiValue: CustomMultiValue }}
      styles={{
        menuPortal: (provided) => {
          return {
            ...provided,
            zIndex: 10000,
            backgroundColor: theme.colors.background,
            ...customStyles,
          };
        },
        container: (provided) => {
          return {
            ...provided,
            backgroundColor: theme.colors.background,
            ...customStyles,
          };
        },
        valueContainer: (provided) => {
          return {
            ...provided,
            paddingLeft: 0,
            padding: 0,
            fontSize: 14,
            color: theme.colors.text,
          };
        },
        option: (provided) => {
          return {
            ...provided,
            color: theme.colors.text,
            fontSize: 14,
            "&:hover": {
              backgroundColor: theme.colors.primary,
            },
            "&:active": {
              backgroundColor: theme.colors.primary,
            },
          };
        },
        singleValue: (provided) => {
          return {
            ...provided,
            color: theme.colors.text,
          };
        },
        menu: (provided) => {
          return {
            ...provided,
            backgroundColor: theme.colors.background,
            ...customStyles,
          };
        },
        control: (provided, { isFocused, menuIsOpen, isDisabled }) => {
          const result = {
            ...provided,
            borderWidth: 2,
            backgroundColor: theme.colors.background,

            paddingLeft: 10,
            minHeight: "auto",
            borderColor: "transparent",

            fontFamily: theme.fonts.body,
            "&:hover": {
              borderColor: "red",
            },
            borderRadius: theme.roundness,
            ...customStyles,
          };

          if (isFocused || menuIsOpen) {
            result.outline = "none";
            result.boxShadow = "none";
            result.backgroundColor = theme.colors.background;
            result.borderColor = theme.colors.accent;
          }

          if (isDisabled) {
            result.backgroundColor = theme.colors.background;
          }

          return result;
        },
      }}
      options={options}
    />
  );
};
