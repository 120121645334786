import { Model, Query, Repo, Timestamp } from "@opencraft/common";
import { useCallback, useEffect, useState } from "react";

export { Timestamp };

export function useRepo<T extends Model>(repo: Repo<T>) {
  return {
    useQuery: (defaultQuery: Query<T>, dependencies?: any[]) => {
      return useQuery(repo, defaultQuery, dependencies);
    },
    useGet: (id?: string) => {
      return useGet(repo, id);
    },
    update: async (id: string, data: Partial<T>) => {
      return repo.update({
        id: id,
        ...data,
      });
    },
    create: async (data: Partial<T>) => {
      return repo.create({
        ...data,
      });
    },
    useAll: (dependencies?: any[]) => {
      return useAll(repo, dependencies);
    },
  };
}

export function useQuery<T extends Model>(
  repo: Repo<T>,
  defaultQuery: Query<T>,
  dependencies: any[] = []
): [boolean, T[], (q: Query<T>) => void] {
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);

  const [q, setQ] = useState<Query<T>>(defaultQuery);

  useEffect(() => {
    if (dependencies.every((d) => d)) {
      setLoading(true);
      // setQ(defaultQuery);

      return repo.subscribe(defaultQuery, (res) => {
        setData(res.data);
        setLoading(false);
      });
    }
  }, [q, ...dependencies]);

  const updateQuery = useCallback((q: Query<T>) => {
    setQ(q);
  }, []);

  return [loading, data, updateQuery];
}

export function useGet<T extends Model>(
  repo: Repo<T>,
  id?: string
): [boolean, T] {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      return repo.subscribeToID(id, (res) => {
        setData(res.data);
        setLoading(false);
      });
    }
  }, [id]);

  return [loading, data];
}

export function useAll<T extends Model>(
  repo: Repo<T>,
  dependencies: any[] = []
): [boolean, T[]] {
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    return repo.subscribeToAll((res) => {
      setData(res.data);
      setLoading(false);
    });
  }, [...dependencies]);

  return [loading, data];
}
