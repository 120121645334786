import {
  Company,
  CompanyID,
  CompanyRepo,
  GigID,
  MessageConnection,
  MessageConnectionRepo,
  ProfileRepo,
  UserID,
} from "@opencraft/common";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createDrawerNavigator, DrawerItem } from "@react-navigation/drawer";
import {
  LinkingOptions,
  NavigationContainer,
  NavigatorScreenParams,
  useNavigation,
} from "@react-navigation/native";
import {
  createStackNavigator,
  StackNavigationProp,
} from "@react-navigation/stack";
import React from "react";
import { useContext } from "react";
import { Platform, Pressable, View } from "react-native";
import { Avatar } from "../components/Avatar";
import { LoggedInErrorBoundary } from "../components/LoggedInErrorBoundary";
import { NavBar } from "../components/Navbar";
import { SignInDialog } from "../components/SignInDialog";
import { AppText as Text, Title } from "../components/StyledText";
import {
  CompanyDetail,
  CreateGig,
  Home,
  JobSearch,
  OnboardingFreelancerCurrencies,
  OnboardingFreelancerName,
  OnboardingFreelancerPhoto,
  OnboardingFreelancerSignin,
  OnboardingFreelancerStart,
  OnboardingProjectBio,
  OnboardingProjectCurrencies,
  OnboardingProjectLinks,
  OnboardingProjectSignIn,
  OnboardingProjectStart,
  ProfileDetail,
  ProfileSearch,
  SignIn,
  Splash,
} from "../screens";
import { GigDetail } from "../screens/GigDetail";
import { MessageDetail, MessageDetailEmpty } from "../screens/MessageDetail";
import { MessagesView } from "../screens/Messages";
import {
  useCurrentUser,
  UserContext,
  useRequiredCurrentUser,
} from "../util/auth";
import { useBreakpoints } from "../util/hooks";
import { Preload } from "../util/Preloadable";
import { useRepo } from "../util/store";
import { useTheme } from "../util/theme";

export type RootNavParams = {
  Home: undefined;
  SignIn: undefined;
  Splash: undefined;

  App: NavigatorScreenParams<AppNavParams>;
  OnboardingFreelancerStart: {
    step: string;
  };
  OnboardingFreelancerName: {
    step: string;
  };
  OnboardingFreelancerCurrencies: {
    step: string;
  };
  OnboardingFreelancerPhoto: {
    step: string;
  };
  OnboardingFreelancerSignin: {
    step: string;
  };
  OnboardingProjectStart: {
    step: string;
  };
  OnboardingProjectCurrencies: {
    step: string;
  };
  OnboardingProjectSignIn: {
    step: string;
  };
  OnboardingProjectLinks: {
    step: string;
  };
  OnboardingProjectBio: {
    step: string;
  };
};

export type AppNavParams = {
  ProfileSearch: undefined;
  JobSearch: undefined;
  Messages: NavigatorScreenParams<MessageNavParams>;

  CreateGig: {
    companyId: CompanyID;
  };
  EditGig: {
    companyId: CompanyID;
    id: GigID;
  };
  CompanyDetail: {
    id: CompanyID;
  };

  GigDetail: {
    id: GigID;
    companyId: CompanyID;
  };
  ProfileDetail: {
    id: UserID;
  };
  MessageDetail: {
    id: string;
  };
};

export type MessageNavParams = {
  MessageDetail: {
    id: string;
  };
  MessageDetailEmpty: undefined;
};

export type DrawerNavParams = AppNavParams;

const Tabs = createBottomTabNavigator<AppNavParams>();
const MessageTabs = createBottomTabNavigator<MessageNavParams>();
const Stack = createStackNavigator<RootNavParams>();
const MobileDrawer = createDrawerNavigator<DrawerNavParams>();

const linking: LinkingOptions<RootNavParams> = {
  prefixes: ["https://opencraft.com", "opencraft://"],
  config: {
    screens: {
      Home: "home",
      App: {
        screens: {
          ProfileSearch: "profiles/search",
          JobSearch: "jobs/search",
          CompanyDetail: "org/:id",
          GigDetail: "org/:companyId/gig/:id",
          ProfileDetail: "profile/:id",
          MessageDetail: "messages/:id",
          CreateGig: "org/:companyId/gig",
          EditGig: "org/:companyId/gig/:id/edit",
          Messages: {
            screens: {
              MessageDetailEmpty: "messages",
              MessageDetail: "messages/inset/:id",
            },
          },
        },
      },
      SignIn: "signin",
      Splash: "intro",
      OnboardingFreelancerStart: "onboarding/freelancer",
      OnboardingFreelancerName: "onboarding/freelancer/handle",
      OnboardingFreelancerCurrencies: "onboarding/freelancer/currencies",
      OnboardingFreelancerSignin: "onboarding/freelancer/signin",
      OnboardingProjectStart: "onboarding/project",
      OnboardingProjectCurrencies: "onboarding/project/currencies",
      OnboardingProjectSignIn: "onboarding/project/signin",
      OnboardingProjectLinks: "onboarding/project/links",
      OnboardingProjectBio: "onboarding/project/bio",
    },
  },
};

/**
 *
 * @param screen the name of the CURRENT screen
 * @returns a typed navigator
 */
export function useNav<T extends keyof RootNavParams>(screen: T) {
  const nav = useNavigation<StackNavigationProp<RootNavParams, T>>();
  return nav;
}

export const RootNavWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <NavigationContainer linking={linking}>{children}</NavigationContainer>
  );
};

export const RootNavigator = (props) => {
  const theme = useTheme();

  const [mobile, tablet] = useBreakpoints();

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: theme.colors.ambientBG,
      }}
    >
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
        }}
      >
        <Stack.Screen
          options={{
            title: "OpenCraft / Welcome",
          }}
          name="Splash"
          component={Splash}
        />
        <Stack.Screen
          options={{
            title: "OpenCraft",
          }}
          name="Home"
          component={Home}
        />

        <Stack.Screen
          options={{
            title: "OpenCraft",
          }}
          name="App"
          component={!tablet ? DrawerNavigator : TabNavigator}
        />

        <Stack.Screen
          options={{
            title: "OpenCraft / Sign in",
            animationEnabled: true,
          }}
          name="SignIn"
          component={SignIn}
        />
        <Stack.Group
          screenOptions={{
            animationEnabled: true,
          }}
        >
          <Stack.Screen
            options={{
              title: "OpenCraft / Onboarding / Freelancer / Start",
            }}
            name="OnboardingFreelancerStart"
            component={OnboardingFreelancerStart}
          />
          <Stack.Screen
            options={{
              title: "OpenCraft / Onboarding / Freelancer / Handle",
            }}
            name="OnboardingFreelancerName"
            component={OnboardingFreelancerName}
          />
          <Stack.Screen
            options={{
              title: "OpenCraft / Onboarding / Freelancer / Currencies",
            }}
            name="OnboardingFreelancerCurrencies"
            component={OnboardingFreelancerCurrencies}
          />
          <Stack.Screen
            options={{
              title: "OpenCraft / Onboarding / Freelancer / Pic",
            }}
            name="OnboardingFreelancerPhoto"
            component={OnboardingFreelancerPhoto}
          />
          <Stack.Screen
            options={{
              title: "OpenCraft / Onboarding / Freelancer / Sign In",
            }}
            name="OnboardingFreelancerSignin"
            component={OnboardingFreelancerSignin}
          />
          <Stack.Screen
            options={{
              title: "Opencraft / Onboarding / Project / Start",
            }}
            name="OnboardingProjectStart"
            component={OnboardingProjectStart}
          />
          <Stack.Screen
            options={{
              title: "Opencraft / Onboarding / Project / Sign In",
            }}
            name="OnboardingProjectSignIn"
            component={OnboardingProjectSignIn}
          />
          <Stack.Screen
            options={{
              title: "Opencraft / Onboarding / Project / Bio",
            }}
            name="OnboardingProjectBio"
            component={OnboardingProjectBio}
          />
          <Stack.Screen
            options={{
              title: "Opencraft / Onboarding / Project / Currencies",
            }}
            name="OnboardingProjectCurrencies"
            component={OnboardingProjectCurrencies}
          />
          <Stack.Screen
            options={{
              title: "Opencraft / Onboarding / Project / Links",
            }}
            name="OnboardingProjectLinks"
            component={OnboardingProjectLinks}
          />
        </Stack.Group>
      </Stack.Navigator>
    </View>
  );
};

export const DrawerNavigator = () => {
  const nav = useNav("App");

  return (
    <LoggedInErrorBoundary
      onCatch={(e) => {
        console.log("caught", e);

        if (Platform.OS === "web") {
          window.location.href = "/signin";
        }
      }}
    >
      <DrawerNavContents />
    </LoggedInErrorBoundary>
  );
};

const DrawerNavContents = () => {
  const theme = useTheme();

  const currentUser = useCurrentUser();

  const profileRepo = useRepo(ProfileRepo);
  const [loading, profile] = profileRepo.useGet(currentUser?.id);

  const context = useContext(UserContext);

  return (
    <>
      <SignInDialog
        visible={context.loginModalShouldShow}
        onDismiss={() => {
          // do nothing
          context.cancelLogin();
        }}
      />
      <MobileDrawer.Navigator
        useLegacyImplementation
        drawerContent={(props) => {
          return (
            <View
              style={{
                backgroundColor: theme.colors.background,
                flex: 1,
                justifyContent: "space-between",
              }}
            >
              <View>
                <Title style={{ padding: 20 }}>
                  Open<Text style={{ color: theme.colors.primary }}>/</Text>
                  Craft
                </Title>
                <DrawerItem
                  inactiveTintColor={theme.colors.text}
                  label={"Jobs"}
                  onPress={() => {
                    props.navigation.navigate("JobSearch");
                  }}
                ></DrawerItem>
                <DrawerItem
                  onPress={() => {
                    props.navigation.navigate("ProfileSearch");
                  }}
                  inactiveTintColor={theme.colors.text}
                  label={"Talent"}
                ></DrawerItem>
                <DrawerItem
                  onPress={() => {
                    props.navigation.navigate("Messages");
                  }}
                  inactiveTintColor={theme.colors.text}
                  label={"Messages"}
                ></DrawerItem>
              </View>
              <Pressable
                onPress={() => {
                  if (currentUser) {
                    props.navigation.navigate("App", {
                      screen: "ProfileDetail",
                      params: {
                        id: currentUser?.id,
                      },
                    });
                  } else {
                    props.navigation.navigate("SignIn");
                  }
                }}
                style={{
                  padding: theme.spacing.gutter,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {profile && <Avatar profile={profile} size={50} />}
                <Text style={{ marginLeft: 10 }}>
                  {currentUser ? "Profile" : "Sign In"}
                </Text>
              </Pressable>
            </View>
          );
        }}
        screenOptions={{
          drawerType: "back",
          sceneContainerStyle: {
            backgroundColor: theme.colors.background,
          },
          headerTitleAllowFontScaling: true,

          headerStyle: {
            backgroundColor: theme.colors.ambientBG,
          },
          headerTintColor: theme.colors.text,
          drawerContentContainerStyle: {
            backgroundColor: theme.colors.ambientBG,
          },
        }}
      >
        <MobileDrawer.Screen
          options={{
            title: "Talent",
          }}
          name="ProfileSearch"
          component={ProfileSearch}
        />
        <MobileDrawer.Screen
          options={{
            title: "Jobs",
          }}
          name="JobSearch"
          component={JobSearch}
        />

        <MobileDrawer.Screen
          options={{
            title: "Messages",
          }}
          name="Messages"
          component={MobileMessagesScreen}
        />
        <MobileDrawer.Screen
          options={{
            title: "Profile",
          }}
          name="ProfileDetail"
          component={ProfileDetail}
        />
        <MobileDrawer.Screen
          options={{
            title: "Messages",
          }}
          name="MessageDetail"
          component={MessageDetail}
        />
        <MobileDrawer.Screen
          options={{
            title: "Company",
          }}
          name="CompanyDetail"
          component={CompanyDetail}
        />
        <MobileDrawer.Screen
          options={{
            title: "Gig",
          }}
          name="GigDetail"
          component={GigDetail}
        />
      </MobileDrawer.Navigator>
    </>
  );
};

export const TabNavigator = () => {
  const nav = useNav("App");

  return (
    <LoggedInErrorBoundary
      onCatch={(e) => {
        console.log("caught", e);

        if (Platform.OS === "web") {
          window.location.href = "/signin";
        }
      }}
    >
      <TabNavContents />
    </LoggedInErrorBoundary>
  );
};

const TabNavContents = () => {
  const theme = useTheme();

  const context = useContext(UserContext);

  return (
    <>
      <SignInDialog
        visible={context.loginModalShouldShow}
        onDismiss={() => {
          // do nothing
          context.cancelLogin();
        }}
      />
      <Tabs.Navigator
        screenOptions={{
          headerShown: false,
        }}
        sceneContainerStyle={{
          paddingTop: 80,
          backgroundColor: theme.colors.background,
        }}
        tabBar={(props) => {
          return <NavBar {...props} />;
        }}
      >
        <Tabs.Screen
          options={{
            title: "OpenCraft / Profiles / Search",
          }}
          name="ProfileSearch"
          component={ProfileSearch}
        />
        <Tabs.Screen
          options={{
            title: "OpenCraft / Jobs / Search",
          }}
          name="JobSearch"
          component={JobSearch}
        />
        <Tabs.Screen
          options={{
            title: "OpenCraft / Gig",
          }}
          name="GigDetail"
          component={GigDetail}
        />
        <Tabs.Screen
          options={{
            title: "OpenCraft / Company",
          }}
          name="CompanyDetail"
          component={CompanyDetail}
        />
        <Tabs.Screen
          options={{
            title: "OpenCraft / Profile",
          }}
          name="ProfileDetail"
          component={ProfileDetail}
        />
        <Tabs.Screen
          options={{
            title: "OpenCraft / Create Gig",
          }}
          name="CreateGig"
          component={CreateGig}
        />
        <Tabs.Screen
          options={{
            title: "OpenCraft / Edit Gig",
          }}
          name="EditGig"
          component={CreateGig}
        />
        <Tabs.Screen
          options={{
            title: "OpenCraft / Messages",
          }}
          name="Messages"
          component={MessageNavigator}
        />
      </Tabs.Navigator>
    </>
  );
};

type ProvidedProps = {};
type Props = ProvidedProps & {
  preloaded: {
    company?: Company;
    connections: MessageConnection[];
  };
};

export const LoadedMessageNavigator = (props: Props) => {
  const theme = useTheme();

  const currentUser = useRequiredCurrentUser();

  const nav = useNav("App");

  const firstChat =
    props.preloaded.connections.length > 0 && props.preloaded.connections[0];
  return (
    <MessageTabs.Navigator
      screenOptions={{
        headerShown: false,
      }}
      sceneContainerStyle={{
        marginLeft: 300,
      }}
      initialRouteName={firstChat ? "MessageDetail" : "MessageDetailEmpty"}
      tabBar={(tabbarProps) => {
        const route = tabbarProps.state.routes[tabbarProps.state.index];

        console.log("active route", route);
        return <MessagesView {...props} />;
      }}
    >
      <MessageTabs.Screen
        component={MessageDetailEmpty}
        name="MessageDetailEmpty"
      />
      <MessageTabs.Screen
        initialParams={{
          id: firstChat && firstChat.id,
        }}
        component={MessageDetail}
        name="MessageDetail"
      />
    </MessageTabs.Navigator>
  );
};

export const MessageNavigator = Preload<{}, { company?: Company }>(
  LoadedMessageNavigator,
  preloadMessageData
);

export const MobileMessagesScreen = Preload<{}, { company?: Company }>(
  MessagesView,
  preloadMessageData
);

async function preloadMessageData(props, { currentUser }) {
  if (!currentUser) {
    return {};
  }

  const companies = await CompanyRepo.query({
    where: [
      {
        field: "owner",
        op: "==",
        value: currentUser.id,
      },
    ],
  });

  const company = companies.length > 0 ? companies[0] : undefined;

  let connections: MessageConnection[] = [];
  let userResults: MessageConnection[] = [];

  if (currentUser) {
    userResults = await MessageConnectionRepo.query({
      where: [
        {
          field: "user",
          op: "==",
          value: currentUser.id,
        },
      ],
    });
  }

  connections = connections.concat(userResults);

  if (company) {
    const companyResults = await MessageConnectionRepo.query({
      where: [
        {
          field: "company",
          op: "==",
          value: company.id,
        },
      ],
    });

    connections = connections.concat(companyResults);
  }

  return { company, connections };
}
