import {
  Company,
  CompanyRepo,
  getApp,
  MessageConnection,
  MessageConnectionRepo,
} from "@opencraft/common";
import * as ImagePicker from "expo-image-picker";
import {
  getDownloadURL,
  ref as storageRef,
  uploadString,
} from "firebase/storage";
import { useEffect, useState } from "react";
import { Platform } from "react-native";
import { useMediaQuery } from "react-responsive";
import { useCurrentUser } from "./auth";
import { isUnread } from "./messageConnection";
import { useRepo } from "./store";

export const useCompany = (): [boolean, Company | null] => {
  const currentUser = useCurrentUser();

  const { create, update, useQuery } = useRepo(CompanyRepo);

  const [loading, company] = useQuery(
    {
      where: [
        {
          field: "owner",
          op: "==",
          value: currentUser?.id,
        },
      ],
    },
    [currentUser?.id]
  );

  return [loading, company.length > 0 ? company[0] : null];
};

export const useImagePicker = () => {
  const [image, setImage] = useState<ImagePicker.ImageInfo | undefined>(
    undefined
  );
  const user = useCurrentUser();

  const pickImage = async () => {
    console.log("trying to pick");
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      quality: 1,
      base64: true,
    });

    if (result.cancelled === false) {
      console.log(result.base64);

      setImage(result);
    }
  };

  const saveImage = async () => {
    const { storage } = getApp();
    const imageRef = storageRef(storage, `pp/${user.id}.jpg`);

    if (Platform.OS != "web") {
      throw new Error("Uploading currently only supported for web");
    }

    if (!image) {
      throw new Error("You must choose an image before saving it.");
    }

    const metadata = {
      contentType: "image/jpeg",
      ownerId: user.id,
    };

    try {
      const result = await uploadString(
        imageRef,
        `data:image/jpeg;base64,${image.base64}`,
        "data_url",
        metadata
      );

      return "firebase://" + result.metadata.bucket + "/" + result.ref.fullPath;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  return { image, pickImage, saveImage };
};

export interface HasPic {
  id: string;
  pic: string;
}

export const useImage = (profile: HasPic) => {
  const { storage } = getApp();

  const [loading, setLoading] = useState(false);
  const [URL, setURL] = useState<string | null>(null);

  const loadURL = async () => {
    if (profile.pic) {
      setLoading(true);
      const imageRef = storageRef(storage, `pp/${profile.id}.jpg`);
      const dlURL = await getDownloadURL(imageRef);
      setURL(dlURL);
      setLoading(false);
    } else {
      setURL(null);
    }
  };

  useEffect(() => {
    loadURL();
  }, [profile]);

  return [loading, URL];
};

export const useUnreadMessages = () => {
  const currentUser = useCurrentUser();
  const [loadingCompany, company] = useCompany();

  const [unreadCompanyConnections, setUnreadCompanyConnections] = useState<
    MessageConnection[]
  >([]);
  const [unreadProfileConnections, setUnreadProfileConnections] = useState<
    MessageConnection[]
  >([]);

  useEffect(() => {
    if (company && currentUser) {
      let unsub = MessageConnectionRepo.subscribe(
        {
          where: [
            {
              field: "company",
              op: "==",
              value: company.id,
            },
          ],
        },
        (res) => {
          setUnreadCompanyConnections(res.data);
        }
      );

      return () => {
        unsub();
      };
    }
  }, [company, currentUser]);

  useEffect(() => {
    if (currentUser) {
      let unsub = MessageConnectionRepo.subscribe(
        {
          where: [
            {
              field: "user",
              op: "==",
              value: currentUser.id,
            },
          ],
        },
        (res) => {
          setUnreadProfileConnections(res.data);
        }
      );

      return () => {
        unsub();
      };
    }
  }, [currentUser]);

  return [
    ...unreadProfileConnections.filter((m) =>
      isUnread(m, company, currentUser)
    ),
    ...unreadCompanyConnections.filter((m) =>
      isUnread(m, company, currentUser)
    ),
  ];
};

export const useBreakpoints = () => {
  const threeFifty = useMediaQuery({
    minWidth: 350,
  });

  const sixHundred = useMediaQuery({
    minWidth: 600,
  });

  const nineFifty = useMediaQuery({
    minWidth: 950,
  });

  const twelveFifty = useMediaQuery({
    minWidth: 1250,
  });

  return [threeFifty, sixHundred, nineFifty, twelveFifty];
};
