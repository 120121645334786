import React from "react";
import { UserNotLoggedInError } from "../util/auth";
import { Dialog } from "./Dialog";
import { SignInDialog } from "./SignInDialog";
import { SignInLockup } from "./SignInLockup";

export class LoggedInErrorBoundary extends React.Component<
  {
    children: React.ReactNode;
    onCatch: (e: any) => void;
  },
  { hasError: boolean; error: string; signInVisible: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: "", signInVisible: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (error instanceof UserNotLoggedInError) {
      this.props.onCatch(error);
    } else if ("message" in error) {
      this.setState({ hasError: true, error: error.message });
    }

    console.log(error);

    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>{this.state.error}</h1>;
    }

    return (
      <>
        <SignInDialog
          visible={this.state.signInVisible}
          onDismiss={() => {
            this.setState({ signInVisible: false });
          }}
        />
        {this.props.children}
      </>
    );
  }
}
