import React from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { useTheme } from "../util/theme";
import { Label } from "./StyledText";

type Props = {
  options: { label: string; value: string }[];
  value: string;
  label: string;
  onChange: (val: string) => void;
};

export const ButtonSelect = ({ value, options, onChange, label }: Props) => {
  const theme = useTheme();

  return (
    <>
      <Label>{label}</Label>
      <View
        style={[
          S.container,
          {
            borderWidth: 2,
            borderColor: theme.colors.primary,
            borderRadius: theme.roundness,
            borderRightWidth: 0,
          },
        ]}
      >
        {options.map((v) => (
          <Pressable
            // @ts-ignore
            style={({ hovered }) => [
              S.option,
              {
                borderRightWidth: 2,
                borderRightColor: theme.colors.primary,
              },
              {
                opacity: hovered ? 0.7 : 1,
              },
              v.value === value
                ? {
                    backgroundColor: theme.colors.primary,
                  }
                : {},
            ]}
            key={v.value}
            onPress={() => {
              onChange(v.value);
            }}
          >
            <Text
              style={[
                S.optionText,
                {
                  color: theme.colors.primary,
                },
                v.value === value
                  ? {
                      color: "white",
                    }
                  : {},
              ]}
            >
              {v.label}
            </Text>
          </Pressable>
        ))}
      </View>
    </>
  );
};

const S = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: 20,
  },

  option: {
    padding: 10,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },

  optionText: {
    fontWeight: "bold",
    textTransform: "uppercase",
  },
});
