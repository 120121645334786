import React, { ComponentProps, ReactNode } from "react";

import { Ionicons } from "@expo/vector-icons";
import { StyleSheet, View } from "react-native";
import { Popable } from "react-native-popable";
import { Spacing, useTheme } from "../util/theme";
import { AppText as Text } from "./StyledText";

type IconProps = Pick<ComponentProps<typeof Ionicons>, "name">;
type Props = {
  Value: ReactNode;
  icon: {
    label: string;
  } & IconProps;
};
export function DetailRow({ Value, icon: { name, label } }: Props) {
  const theme = useTheme();

  if (!Value) {
    return null;
  }

  return (
    <View
      style={{ flexDirection: "row", alignItems: "center", marginVertical: 10 }}
    >
      <Popable action="hover" content={label}>
        <Ionicons
          color={theme.colors.text}
          size={30}
          name={name}
          style={{ marginRight: 10 }}
        />
      </Popable>
      {typeof Value === "string" ? <Text>{Value}</Text> : <Value />}
    </View>
  );
}

type BasicDetailsProps = {
  children: typeof DetailRow[];
};
export const BasicDetails = (props: BasicDetailsProps) => {
  return (
    <View style={S.basicDetails}>
      <Text style={[S.basicDetailsTitle]}>Basic Details</Text>
      {props.children}
    </View>
  );
};

const S = StyleSheet.create({
  basicDetails: {
    paddingVertical: Spacing.spacing.padding,
  },

  basicDetailsTitle: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
});
