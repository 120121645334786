import React from "react";
import { StyleSheet, Text, TextProps } from "react-native";
import { Fonts, useTheme } from "../util/theme";

type TitleProps = TextProps;

export const Title = (props: TitleProps) => {
  const theme = useTheme();
  return (
    <Text
      {...props}
      style={[
        S.title,
        props.style,
        {
          color: theme.colors.text,
        },
      ]}
    />
  );
};

export const AppText = (props: TextProps) => {
  const theme = useTheme();
  return (
    <Text
      {...props}
      style={[
        {
          color: theme.colors.text,
        },
        props.style,
      ]}
    />
  );
};

export const Label = (props: TextProps) => {
  const theme = useTheme();
  return (
    <Text
      {...props}
      style={[
        {
          fontWeight: "bold",
          marginVertical: 10,
          marginTop: 15,
          color: theme.colors.text,

        },
        props.style,
      ]}
    />
  );
};

const S = StyleSheet.create({
  title: {
    fontFamily: Fonts.fonts.title,
    fontSize: 24,
  },
});
