import { Profile } from "@opencraft/common";

export const getProfileCompletion = (
  profile?: Profile
): [number, string | null] => {
  const total = 9;
  let tally = 0;

  let nextStep = null;

  if (!profile) return [0, null];

  if (profile.handle) {
    tally++;
  } else {
    nextStep = "Add a handle.";
  }

  if (profile.bio && profile.bio.length > 0) {
    tally++;
  } else if (!nextStep) {
    nextStep = "Add a bio to share a little bit more about what you can do.";
  }

  if (profile.pic && profile.pic.length > 0) {
    tally++;
  } else if (!nextStep) {
    nextStep =
      "Add a profile pic. It could be a doxxed photo of you or something else that represents you.";
  }

  if (profile.preferredCurrencies && profile.preferredCurrencies.length > 0) {
    tally++;
  } else if (!nextStep) {
    nextStep = "Add the preferred currencies you'll except";
  }

  if (profile.github || profile.linkedin || profile.twitter) {
    tally++;
  } else if (!nextStep) {
    nextStep = "Add some links like Github, Twitter or LinkedIn.";
  }

  if (profile.experience) {
    tally++;
  } else if (!nextStep) {
    nextStep =
      "Fill out some basic information like your experience, remote preference and work style.";
  }

  if (profile.remote != null) {
    tally++;
  }

  if (profile.role != null) {
    tally++;
  }

  if (profile.style != null) {
    tally++;
  }

  return [tally / total, nextStep];
};
