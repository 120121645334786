import { ProfileRepo } from "@opencraft/common";
import React from "react";
import { View } from "react-native";
import * as Progress from "react-native-progress";
import { useNav } from "../app/navigation";
import { useCurrentUser } from "../util/auth";
import { getProfileCompletion } from "../util/fns";
import { useRepo } from "../util/store";
import { useTheme } from "../util/theme";
import { Button } from "./Button";
import { Card } from "./Card";
import { AppText as Text, Title } from "./StyledText";

type Props = {
  hideButton?: boolean;
};
export const ProfileCompletionCard = ({ hideButton }: Props) => {
  const user = useCurrentUser();

  const theme = useTheme();
  const { useGet } = useRepo(ProfileRepo);
  const nav = useNav("App");

  const [loadingProfile, profile] = useGet(user?.id);

  const [completionPercent, nextStep] = getProfileCompletion(profile);

  if (!user || loadingProfile || completionPercent == 1) {
    return null;
  }

  return (
    <Card>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <View
          style={{ flexDirection: "column", alignItems: "flex-start", flex: 1 }}
        >
          <Title>Your profile is incomplete</Title>
          {!hideButton ? (
            <Button
              style={{
                alignSelf: "flex-start",
                marginTop: theme.spacing.gutter,
              }}
              onPress={() => {
                nav.navigate("App", {
                  screen: "ProfileDetail",
                  params: {
                    id: user.id,
                  },
                });
              }}
              size="small"
              label="Complete your profile"
            ></Button>
          ) : (
            <Text style={{ marginTop: theme.spacing.padding, width: "70%" }}>
              Next step: {nextStep}
            </Text>
          )}
        </View>

        <Progress.Circle
          size={50}
          progress={completionPercent}
          animated={false}
          showsText={true}
          formatText={(val) => {
            console.log("formatting", val);
            return (val * 100).toFixed(0) + "%";
          }}
          color={theme.colors.accent}
          // @ts-ignore -- web styles
          style={{ fill: "none" }}
        />
      </View>
    </Card>
  );
};
