import { CompanyRepo, MessageConnection, ProfileRepo } from "@opencraft/common";
import React from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { AppText as Text } from "./StyledText";
import { useCurrentUser } from "../util/auth";
import { useBreakpoints } from "../util/hooks";
import { isUnread } from "../util/messageConnection";
import { useRepo } from "../util/store";
import { Spacing, useTheme } from "../util/theme";
import { Avatar } from "./Avatar";

type Props = {
  connection: MessageConnection;
  onPress: () => void;
};
export const ChatPreview = ({ connection, onPress }: Props) => {
  const user = useCurrentUser();
  const theme = useTheme();
  const { useGet } = useRepo(CompanyRepo);
  const { useGet: getProfile } = useRepo(ProfileRepo);
  const [loading, company] = useGet(connection.company);
  const [loadingProfile, profile] = getProfile(connection.user);

  const [mobile, tablet] = useBreakpoints();

  if (!company || !profile) {
    return null;
  }

  return (
    <Pressable
      style={({ hovered }) => {
        return [
          S.row,
          {
            borderBottomColor: theme.colors.border,
            backgroundColor: hovered
              ? theme.colors.hoverAccent
              : theme.colors.background,
          },
          !tablet && {
            flex: 1,
            width: "100%",
          },
        ];
      }}
      onPress={onPress}
    >
      <View style={{ flexDirection: "row" }}>
        <Avatar
          size={40}
          profile={connection.user == user?.id ? company : profile}
        />
        <View style={{ marginLeft: 10 }}>
          <Text style={[S.header]}>
            {connection.user == user?.id ? company.name : profile.handle}
          </Text>
          {connection.lastMessage ? (
            <View>
              <Text>{connection.lastMessage.content}</Text>
              <Text
                style={{
                  marginTop: 5,
                  color: theme.colors.disabled,
                  fontStyle: "italic",
                }}
              >
                {connection.lastMessage.sentAt.toDate().toLocaleDateString()}{" "}
                {connection.lastMessage.sentAt.toDate().toLocaleTimeString()}
              </Text>
            </View>
          ) : (
            <Text style={{ color: theme.colors.disabled, fontStyle: "italic" }}>
              No recent messages
            </Text>
          )}
        </View>
      </View>
      {isUnread(connection, company, user) && (
        <View
          style={{
            height: 10,
            width: 10,
            backgroundColor: theme.colors.primary,
            alignSelf: "center",
            borderRadius: 5,
          }}
        ></View>
      )}
    </Pressable>
  );
};

const S = StyleSheet.create({
  row: {
    borderBottomWidth: 1,
    padding: Spacing.spacing.gutter,
    flexDirection: "row",
    justifyContent: "space-between",
  },

  header: {
    fontWeight: "bold",
    marginBottom: 5,
  },
});
