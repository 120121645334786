import React, { useState } from "react";
import { RootNavParams, useNav } from "../app/navigation";

import { useTheme } from "../util/theme";

import { Company, CompanyRepo } from "@opencraft/common";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { View } from "react-native";
import { MultiSelect } from "../components/MultiSelect.web";
import { OnboardingView } from "../components/OnboardingView";
import { CryptoOptions, CryptoOptionsMap, OptionShape } from "../util/crypto";
import { useCompany } from "../util/hooks";
import { Preload } from "../util/Preloadable";
import { useRepo } from "../util/store";

type Props = NativeStackScreenProps<
  RootNavParams,
  "OnboardingProjectCurrencies"
>;

type PreloadedProps = {
  company?: Company;
};

export const OnboardingProjectCurrencies = Preload<Props, PreloadedProps>(
  (props) => {
    const { step } = props.route.params;
    const nav = useNav("OnboardingProjectCurrencies");
    const theme = useTheme();

    const defaultCo = props.preloaded.company;

    const [values, setValues] = useState<OptionShape[]>(
      defaultCo
        ? defaultCo.currencies.map((v) => CryptoOptionsMap[v.toUpperCase()])
        : ["usdc", "usdt"].map((v) => CryptoOptionsMap[v.toUpperCase()])
    );

    const { update } = useRepo(CompanyRepo);

    const [loading, company] = useCompany();

    return (
      <OnboardingView
        loading={loading}
        step={parseInt(step)}
        title="Which Currencies Do You Use?"
        description="On OpenCraft, rates are negotiated and paid in crypto. Typically freelancers are willing to accept USDC or USDT. Let freelancers know what you're open to using."
        onNext={() => {
          update(company.id, {
            currencies: values.map((v) => v.symbol),
          });

          nav.navigate("OnboardingProjectLinks", {
            step: `${parseInt(step) + 1}`,
          });
        }}
      >
        <View style={{ marginTop: 50 }}>
          <MultiSelect
            multi
            value={values}
            style={{ backgroundColor: theme.colors.ambientBG }}
            options={CryptoOptions}
            placeholder="Select preferred currencies"
            onChange={(result) => {
              if (Array.isArray(result)) {
                setValues(result);
              }
            }}
          />
        </View>
      </OnboardingView>
    );
  },
  async (props, context) => {
    const co = await CompanyRepo.query({
      where: [
        {
          field: "owner",
          op: "==",
          value: context.currentUser?.id,
        },
      ],
    });

    return {
      company: co.length > 0 ? co[0] : undefined,
    };
  }
);
