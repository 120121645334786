import React, { useState } from "react";
import { RootNavParams, useNav } from "../app/navigation";

import { useTheme } from "../util/theme";

import { Company, CompanyRepo } from "@opencraft/common";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { OnboardingView } from "../components/OnboardingView";
import { TextInput } from "../components/TextInput";
import { useCurrentUser } from "../util/auth";
import { useCompany } from "../util/hooks";
import { Preload } from "../util/Preloadable";
import { useRepo } from "../util/store";

type Props = NativeStackScreenProps<RootNavParams, "OnboardingProjectStart">;

type PreloadedProps = {
  company?: Company;
};

export const OnboardingProjectStart = Preload<Props, PreloadedProps>(
  (props) => {
    const { step } = props.route.params;
    const nav = useNav("OnboardingProjectStart");
    const theme = useTheme();

    const defaultCo = props.preloaded.company;
    const [name, setName] = useState(defaultCo?.name);
    const currentUser = useCurrentUser();

    const { create, update, useQuery } = useRepo(CompanyRepo);

    const [loading, company] = useCompany();

    return (
      <OnboardingView
        step={parseInt(step)}
        loading={loading}
        title="Start Finding Freelancers"
        description="We'll go through a few steps to create your project's profile. First, give us the name of your project. This will help freelancers find you."
        onNext={async () => {
          if (defaultCo?.name != name) {
            if (company) {
              await update(company.id, {
                name: name,
              });
            } else {
              await create({
                owner: currentUser.id,
                name: name,
              });
            }
          }

          nav.navigate("OnboardingProjectBio", {
            step: `${parseInt(step) + 1}`,
          });
        }}
      >
        <TextInput
          value={name}
          onChangeText={setName}
          style={{
            marginVertical: 50,
            backgroundColor: theme.colors.ambientBG,
          }}
          placeholder="name"
        ></TextInput>
      </OnboardingView>
    );
  },
  async (props, context) => {
    const co = await CompanyRepo.query({
      where: [
        {
          field: "owner",
          op: "==",
          value: context.currentUser?.id,
        },
      ],
    });

    return {
      company: co.length > 0 ? co[0] : undefined,
    };
  }
);
