import {
  getApp,
  Profile,
  ProfileRepo,
  User,
  UserRepo,
} from "@opencraft/common";
import { onAuthStateChanged } from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import { authAnon } from "../app/auth";
import { useRepo } from "./store";

export class UserNotLoggedInError extends Error {}

export type CurrentUserContextParams = {
  logout: () => void;
  loginModalShouldShow: boolean;
  currentUser?: User;
  profile?: Profile;
  login: () => void;
  cancelLogin: () => void;
};

export const UserContext = React.createContext<CurrentUserContextParams>({
  currentUser: undefined,
  loginModalShouldShow: false,
  profile: undefined,
  logout: () => undefined,
  login: () => undefined,
  cancelLogin: () => undefined,
});

export const useLogin = (): [boolean, User | null] => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  const userRepo = useRepo(UserRepo);

  // after we log in, subscribe to the user so we get updates
  // if fields change like email
  const [loadingUser, subbedUser] = userRepo.useGet(user?.id);

  useEffect(() => {
    const { auth } = getApp();
    const unsub = onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log("found user", user.uid);
        const ocUser = await UserRepo.get(user.uid);
        console.log("loaded user", ocUser);
        setUser(ocUser);
      } else {
        console.log("logging out");
        setUser(null);
      }
      setLoading(false);
    });

    return () => {
      unsub();
    };
  }, []);

  return [loading, subbedUser || user];
};

export const useRequiredCurrentUser = () => {
  const result = useContext(UserContext);

  const { auth } = getApp();
  if (
    !result.currentUser ||
    (auth.currentUser && auth.currentUser.isAnonymous)
  ) {
    throw new UserNotLoggedInError("User is not logged in");
  }

  return result.currentUser!;
};

export const useCurrentUser = (): User | undefined => {
  const result = useContext(UserContext);

  return result.currentUser;
};

export const useLogout = () => {
  const result = useContext(UserContext);
  return result.logout;
};

export const useTriggerLogin = () => {
  const result = useContext(UserContext);
  return result.login;
};

export const useOrEstablishProfile = (): [boolean, Profile | null] => {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<Profile | null>(null);

  const [loggingIn, user] = useLogin();

  useEffect(() => {
    if (!loggingIn) {
      if (user == null) {
        throw new Error("user cannot be null establishing profile");
      } else {
        (async () => {
          let p = await ProfileRepo.get(user.id);

          if (p == null) {
            p = (await ProfileRepo.create({
              id: user.id,
            })) as Profile;
          }

          setProfile(p as Profile);
          setLoading(false);
        })();
      }
    }
  }, [loggingIn]);

  return [loading, profile];
};

export const useOrEstablishUser = (): [boolean, User | null] => {
  const [loading, setLoading] = useState(true);

  const [loggingIn, user] = useLogin();

  useEffect(() => {
    if (!loggingIn) {
      if (user == null) {
        (async () => {
          const user = await authAnon();

          setLoading(false);
        })();
      } else {
        (async () => {
          const user = await authAnon();

          setLoading(false);
        })();
      }
    }
  }, [loggingIn]);

  return [loading, user];
};
