import { ProfileRepo } from "@opencraft/common";
import React from "react";
import { useCompany } from "../util/hooks";
import { useRepo } from "../util/store";
import { Loader } from "./Loader";
import { ProfilePreview } from "./ProfilePreview";

type Props = {
  applicantId: string;
};

export const Application = (props: Props) => {
  const { useGet } = useRepo(ProfileRepo);
  const [loading, profile] = useGet(props.applicantId);
  const [loadingCo, company] = useCompany();

  if (!profile || !company) {
    return <Loader visible />;
  }

  return <ProfilePreview profile={profile} company={company} />;
};
